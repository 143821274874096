/* columns module: core */
// TODO: consider if this would make more sense renamed to grid. .grid-1, .grid-2, etc.

// any element with a class that starts with "grid-"
.grid,
[class^="grid-"],
[class*=' grid-'] {
  display: grid;
}

// 2 column layout, equal widths
.grid-2-col {
  grid-template-columns: 1fr 1fr;
}

// 2 column layout with 5:7 ratio
.grid-2-col-5-7 {
  grid-template-columns: 5fr 7fr;
}

// 2 column layout with 7:5 ratio
.grid-2-col-7-5 {
  grid-template-columns: 7fr 5fr;
}

// 3 column layout, equal widths
.grid-3-col {
  grid-template-columns: 1fr 1fr 1fr;
  @include media-below-desktop {
    grid-template-columns: 1fr 1fr;
  }
}

// 4 column layout, equal widths
.grid-4-col {
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @include media-below-desktop {
    grid-template-columns: 1fr 1fr;
  }
}

// 5 column layout, equal widths
.grid-5-col {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @include media-below-desktop {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-2-col {
  grid-template-columns: 1fr 1fr;
}

@include media-below-desktop {
  .grid-1-col-tablet {
    grid-template-columns: 1fr;
  }
  .grid-2-col-tablet {
    grid-template-columns: 1fr 1fr;
  }
  .grid-3-col-tablet {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@include media-mobile-only {
  .grid-2-col,
  .grid-2-col-5-7,
  .grid-2-col-7-5,
  .grid-3-col,
  .grid-4-col {
    grid-template-columns: 1fr;
  }
}

.grid-no-stretch {
  align-items: flex-start;
}

.grid-vertically-center-children > * {
  align-self: center;
  justify-self: center;
}

@include media-mobile-only {
  .grid-2-col-mobile {
    grid-template-columns: 1fr 1fr;
  }
}
