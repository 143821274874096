/* bg module: core */

.bg-black {
  background-color: $color-black;
}

.bg-white {
  background-color: $color-white;
}

.bg-gray-1 {
  background-color: $color-gray-1;
}

.bg-gray-3 {
  background-color: $color-gray-11;
  padding: 30*$pxToRem 0;
  margin-top: 30*$pxToRem;
}

.bg-gray-texture-1 {
  background: #040404 url(#{$imagesPath}gray-bg-1@2x.jpg) center center/cover no-repeat;
}

.bg-gray-texture-2 {
  background: #040404 url(#{$imagesPath}gray-bg-2@2x.jpg) center center/cover no-repeat;
}

.bg-purple {
  background: #824B66 url(#{$imagesPath}purple-background@2x.jpg) center center/cover no-repeat;
}

.bg-purple-left {
  position: relative;
  &::after {
    content: '';
    background: #824B66 url(#{$imagesPath}purple-background@2x.jpg) center center/cover no-repeat;
    transform: translateX(-3rem) translateY(3rem);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }
}
@include media-mobile-only {
  .bg-purple-left {
    &::after {
      transform: translateX(-1rem) translateY(-1rem);
    }
  }
}

.bg-purple-right {
  position: relative;
  &::after {
    content: '';
    background: #824B66 url(#{$imagesPath}purple-background@2x.jpg) center center/cover no-repeat;
    transform: translateX(3rem) translateY(3rem);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }
}
@include media-mobile-only {
  .bg-purple-right {
    &::after {
      transform: translateX(1rem) translateY(-1rem);
    }
  }
}

.bg-purple-small-right {
  position: relative;
  &::after {
    content: '';
    background: #824B66 url(#{$imagesPath}purple-background@2x.jpg) center center/cover no-repeat;
    transform: translateX(2rem) translateY(2rem);
    width: 300*$pxToRem;
    height: 300*$pxToRem;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}
@include media-mobile-only {
  .bg-purple-small-right {
    &::after {
      transform: translateX(1rem) translateY(1rem);
    }
  }
}

.bg-right {
  position: relative;
  z-index: 2;
  transform: translateX(100px);
}

.bg-left {
  position: relative;
  z-index: 2;
  transform: translateX(-100px);
}

@include media-below-desktop {
  .bg-right {
    transform: translateX(0) translateY(0);
  }

  .bg-left {
    transform: translateX(0) translateY(0);
  }
}

.bg-carecredit {
  background: #393E47 url(#{$imagesPath}care-credit-bg.jpg) center center/cover no-repeat;
  background-position: top center;
}

.painting-bg {
  background:
  url(#{$imagesPath}painting-bg-1.jpg) left top no-repeat,
  url(#{$imagesPath}painting-bg-2.jpg) right top no-repeat;
  background-size: contain;
  @include media-mobile-only {
    background:
    url(#{$imagesPath}painting-bg-1.jpg) center no-repeat;
    background-size: cover;
  }
}


.z-1 {
  z-index: -1;
}
.z2 {
  z-index: 2;
}
.z5 {
  z-index: 5;
}
