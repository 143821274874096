/* inverted module: core */
// TODO consider "dark" or color related instead of "inverted"
// TODO perhaps this should be handled a different way in another file

.inverted {
  color: $color-text-inverted;

  a {
    color: $color-accent;

    &:hover {
      color: $color-text-inverted;
    }
  }

  nav,
  .nav {
    a {
      color: $color-inverted-link;

      &:hover {
        color: $color-inverted-link-hover;
      }
    }
  }

  .nav-vertical {
    li::before {
      background-color: $color-white !important;
    }
  }
}
