.margin-top-25 {
  margin-top: 25*$pxToRem !important;
}

.margin-top-45 {
  margin-top: 45*$pxToVw;

  @include media-lg-monitor-only {
    margin-top: 45*$pxToRem;
  }
}

.margin-bottom-66 {
  margin-bottom: 66*$pxToVw;

  @include media-lg-monitor-only {
    margin-bottom: 66*$pxToRem;
  }
}

.margin-top-70 {
  margin-top: 70*$pxToVw;

  @include media-lg-monitor-only {
    margin-top: 70*$pxToRem;
  }
}

// 76
.margin-default {
  margin: $padding-default-vw auto;

  @include media-lg-monitor-only {
    margin: $padding-default-rem auto;
  }

  @include media-mobile-only {
    margin: $padding-mobile auto;
  }
}

// 76
.margin-top-default {
  margin-top: $padding-default-vw;

  @include media-lg-monitor-only {
    margin-top: $padding-default-rem;
  }
}

.margin-top-100 {
  margin-top: 100*$pxToVw;

  @include media-lg-monitor-only {
    margin-top: 100*$pxToRem;
  }
}

.margin-top-125 {
  margin-top: 125*$pxToVw;

  @include media-lg-monitor-only {
    margin-top: 125*$pxToRem;
  }
}

.banner + .margin-top-125 {
  @include media-mobile-only {
    margin-top: 0;
  }
}

.margin-bottom-100 {
  margin-bottom: 100*$pxToVw;

  @include media-lg-monitor-only {
    margin-bottom: 100*$pxToRem;
  }
}

.margin-bottom-125 {
  margin-bottom: 125*$pxToVw;

  @include media-lg-monitor-only {
    margin-bottom: 125*$pxToRem;
  }
}

.margin-top-180 {
  margin-top: 180*$pxToVw;

  @include media-lg-monitor-only {
    margin-top: 180*$pxToRem;
  }
}

.margin-v-100 {
  margin-top: 100*$pxToVw;
  margin-bottom: 100*$pxToVw;

  @include media-lg-monitor-only {
    margin-top: 100*$pxToRem;
    margin-bottom: 100*$pxToRem;
  }
}


@include media-mobile-only {
  .margin-mobile-top-25 {
    margin-top: 25*$pxToRem !important;
  }
  .margin-mobile-bottom-25 {
    margin-bottom: 25*$pxToRem !important;
  }
  .margin-mobile-top-45 {
    margin-top: 45*$pxToRem !important;
  }
  .margin-mobile-top-75 {
    margin-top: 75*$pxToRem !important;
  }
  .margin-mobile-top-100 {
    margin-top: 100*$pxToRem !important;
  }
  .margin-mobile-bottom-45 {
    margin-bottom: 45*$pxToRem !important;
  }
  .margin-mobile-bottom-200 {
    margin-bottom: 200*$pxToRem !important;
  }
}


.margin-auto {
  margin: auto;
}

@include media-above-mobile {
  .margin-right-auto {
    margin-right: auto;
  }
  .margin-left-auto {
    margin-left: auto;
  }
}
@include media-mobile-only {
  .margin-right-auto {
    margin: auto;
  }
  .margin-left-auto {
    margin: auto;
  }
}
