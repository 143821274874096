/* button module: core */
// TODO: make more logical separation between core and theme styles

// button,
// input[type="button"],
// input[type="submit"] {
//   background-color: transparent;
// }

.button,
.button-icon {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  position: relative;
  //overflow: hidden; // clip box for sliding background
  //padding: 0 26*$pxToRem;
  min-height: 40*$pxToRem;
  align-items: center;
  justify-content: center;
  z-index: 0;
  color: $color-text;
  font-weight: 500;
  transition: color $transition-default;

  // Accent color pipe ornament that animates on hover
  &::before, &::after {
    display: block;
    content: "";
    position: absolute;
    background-color: $color-accent;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    z-index: -1;
    transition: transform $transition-default;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }

  // &::after {
  //   display: block;
  //   content: "";
  //   position: absolute;
  //   background-color: $color-accent;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: -1;
  //   transition: transform $transition-default;
  //   // offset colored background box by default (clipped by parent)
  //   transform: translateX(calc(-100% + #{$border-thickness*$pxToRem}));
  // }

  &:hover {
    color: $color-accent;
    &::before {
      transform: translateY(-6px)
    }
    &::after {
      transform: translateY(6px)
    }
  }
}

.padding-section-content .button:last-child {
  @include media-mobile-only {
    margin-bottom: $padding-mobile;
  }
}

.button-icon {
  svg {
    display: block;
    width: 16*$pxToRem;
    height: 16*$pxToRem;
    margin-right: 10*$pxToRem;
  }
}

.inverted a.button,
.inverted a.button-icon,
a.button.inverted,
a.button-icon.inverted {
  color: $color-white;

  svg {
    fill: $color-white;
  }

  &:hover {
    color: $color-accent;
  }
}


.button-round {
  border: 1px solid $color-white;
  border-radius: 25px;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  position: relative;
  overflow: hidden; // clip box for sliding background
  padding: 5*$pxToRem 40*$pxToRem;
  min-height: 40*$pxToRem;
  min-width: 100*$pxToRem;
  align-items: center;
  justify-content: center;
  z-index: 0;
  color: $color-white;
  font-weight: 500;
  transition: all $transition-default;
  &::before {
    content: '';
    display: block;
    background: rgb(147,32,86);
    background: linear-gradient(90deg, rgba(147,32,86,1) 0%, rgba(93,24,53,1) 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform $transition-default;
    transform: translateX(-100%);
  }
  &:hover {
    color: $color-white;
    border-color: rgba(147,32,86,1);
    &::before {
      transform: translateX(0);
    }
  }
}


.button-gray {
  background-color: $color-gray-1;
  color: $color-white;
  font-weight: 500;
  padding: 25*$pxToRem 40*$pxToRem;
  transition: color $transition-default;
  overflow: hidden;
  position: relative;
  min-height: 190*$pxToRem;
  box-sizing: border-box;
  > * {
    color: $color-white;
    position: relative;
  }
  &::before {
    content: '';
    background-color: $color-accent;
    height: 100%;
    width: 100%;
    transform: translateX(-98%);
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    transition: transform $transition-default;
  }
  &:hover {
    &::before {
      transform: translateX(0);
    }
  }
  @include media-mobile-only {
    min-height: 130*$pxToRem;
  }
}


input[type=submit] {
  background: none;
  outline: 0;
  border-top: 1px solid $color-accent;
  border-bottom: 1px solid $color-accent;
  padding: 20*$pxToRem 0;
  margin: 10*$pxToRem auto;
  transition: all  $transition-default;
  &:hover {
    padding: 30*$pxToRem 0;
    margin: 0 auto;
  }
}
