/* footer module: theme */


.footer {
  background: $color-black;
  color: $color-white;
  // @include config-type-alt-font;
  > * {
    margin: auto;
  }
  a {
    color: $color-white;
    font-weight: 300;
    &:hover {
      color: $color-accent
    }
  }
  address {
    font-weight: 300;
    h3 {
      font-size: 1.25rem;
      text-transform: uppercase;
    }
    h5 {
      font-size: 1.25rem;
      text-transform: uppercase;
    }
    a {
      display: block;
    }
  }
  &__legal {
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.25rem;
    text-align: right;
    ul {
      margin-left: auto;
      justify-content: flex-end;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      display: flex;
      text-transform: uppercase;
      margin-top: 0;
      margin-right: -0.25rem;
      li:not(:last-child)::after {
        content: "|";
      }
      li a {
        padding: 0 0.25rem;
      }
    }
    a {
      font-weight: 500;
    }
  }
  .logo {
    img {
      width: 162px;
      margin-bottom: 5rem;
    }
  }
  .social {
    margin-bottom: 10rem;
    ul {
      justify-content: flex-end;
      a svg {
        fill: $color-white
      }
    }
  }
}

@include media-mobile-only {
  .footer {
    padding-bottom: 5.5rem;
    .logo {
      margin-top: 1rem;
    }
    .social {
      margin: 3rem 0;
      ul {
        justify-content: flex-start;
      }
    }
    &__legal {
      text-align: left;
    }
  }
  .footer__legal ul {
    justify-content: flex-start;
  }
}
