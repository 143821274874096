body,
input,
textarea,
button,
select,
.typography-body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.0625rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.5882352941;
  font-family: "Montserrat", sans-serif;
  color: #000000;
}
@media screen and (max-width: 767px) {
  body,
input,
textarea,
button,
select,
.typography-body {
    font-size: 1.125rem;
    line-height: 1.33;
  }
}

h1,
h2,
.typography-heading {
  font-family: "futura-pt", sans-serif;
  font-size: 3.125rem;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.28;
}
@media screen and (max-width: 767px) {
  h1,
h2,
.typography-heading {
    font-size: 2.25rem;
    line-height: 1.25;
  }
}

h2 span {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 2;
  display: block;
}
@media screen and (max-width: 767px) {
  h2 span {
    line-height: 1.25;
  }
}

h3,
.typography-subheading {
  color: #932056;
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 2;
}
@media screen and (max-width: 767px) {
  h3,
.typography-subheading {
    line-height: 1.25;
  }
}

.typography-subheading-lg {
  font-size: 1.625rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 1.875rem;
  margin-bottom: 0.75rem;
}

.typography-body-jumbo,
.accordion__content {
  font-size: 1.125rem;
}

.typography-script {
  font-family: "Twister", sans-serif;
  font-size: 3.625rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.375rem;
  text-transform: lowercase;
  color: #932056;
}

.typography-regular-case {
  text-transform: none;
}

.typography-heading-lg {
  font-family: "futura-pt", sans-serif;
  font-size: 3.125rem;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.28;
  font-size: 4.375rem;
}
@media screen and (max-width: 767px) {
  .typography-heading-lg {
    font-size: 2.25rem;
    line-height: 1.25;
  }
}

p a,
li a,
td a {
  font-weight: 500;
}

strong,
b,
th,
strong a,
.typography-heavy {
  font-weight: 700;
}

small,
.typography-fine-print {
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
}
@media screen and (max-width: 767px) {
  small,
.typography-fine-print {
    font-size: 1rem;
  }
}

.typography-heading-container,
.typography-heading-container-with-script {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  padding-bottom: 0.5rem;
}
.typography-heading-container h3,
.typography-heading-container-with-script h3 {
  order: -1;
  margin-top: -0.5625rem;
  margin-bottom: -0.5625rem;
}
.typography-heading-container h2,
.typography-heading-container-with-script h2 {
  margin-top: 0 !important;
  position: relative;
  z-index: 2;
}
.typography-heading-container h4,
.typography-heading-container-with-script h4 {
  font-family: "Twister", sans-serif;
  font-size: 3.625rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.375rem;
  text-transform: lowercase;
  color: #932056;
  position: relative;
  width: 100%;
  text-align: right;
  z-index: 1;
  margin: -1rem 0 0 0;
}
.typography-heading-container + p.typography-heavy,
.typography-heading-container-with-script + p.typography-heavy {
  padding-right: 4.375rem;
}
@media screen and (max-width: 767px) {
  .typography-heading-container + p.typography-heavy,
.typography-heading-container-with-script + p.typography-heavy {
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .typography-heading-container-with-script {
    width: 120%;
  }
  .typography-heading-container-with-script h3,
.typography-heading-container-with-script h2 {
    padding-right: 4.375rem;
  }
}

.text-align-center .typography-heading-container {
  margin: auto;
}
.text-align-center .typography-heading-container h2 {
  width: auto;
}

@font-face {
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* align module: core */
.align-children-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.align-center {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

/* base module: core */
html,
body {
  width: 100%;
  height: 100%;
  background: #ffffff;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
figure {
  margin: 0;
}

header,
section,
footer {
  position: relative;
}

a {
  color: #000000;
  text-decoration: none;
  transition: color 0.2s ease-in;
}
a:hover {
  color: #932056;
}

img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center center;
}

p:first-child,
ul:first-child,
ol:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
hr:first-child,
a:first-child,
img:first-child,
code:first-child,
pre:first-child,
table:first-child,
blockquote:first-child,
table:first-child,
dl:first-child {
  margin-top: 0;
}
p:last-child,
ul:last-child,
ol:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
hr:last-child,
a:last-child,
img:last-child,
code:last-child,
pre:last-child,
table:last-child,
blockquote:last-child,
table:last-child,
dl:last-child {
  margin-bottom: 0;
}

button,
input[type=button],
input[type=submit] {
  display: block;
  appearance: none;
  border: none;
  cursor: pointer;
}

nav ul {
  list-style: none;
  padding: 0;
}

:is(a, button, input, textarea, select, [role=button]):focus, :is(a, button, input, textarea, select, [role=button]):focus-visible {
  outline: 0.125rem solid #000000;
  outline-offset: 0.125rem;
}
:is(a, button, input, textarea, select, [role=button]):focus:not(:focus-visible) {
  outline: none;
}

.header :is(a, button, input, textarea, select, [role=button]):focus, .header :is(a, button, input, textarea, select, [role=button]):focus-visible,
.banner :is(a, button, input, textarea, select, [role=button]):focus,
.banner :is(a, button, input, textarea, select, [role=button]):focus-visible,
.inverted :is(a, button, input, textarea, select, [role=button]):focus,
.inverted :is(a, button, input, textarea, select, [role=button]):focus-visible {
  outline-color: #ffffff;
}

address {
  font-style: normal;
}

/* bg module: core */
.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: #ffffff;
}

.bg-gray-1 {
  background-color: #363636;
}

.bg-gray-3 {
  background-color: #FAFAFA;
  padding: 1.875rem 0;
  margin-top: 1.875rem;
}

.bg-gray-texture-1 {
  background: #040404 url(/wp-content/themes/aestheticcontours_com/images/gray-bg-1@2x.jpg) center center/cover no-repeat;
}

.bg-gray-texture-2 {
  background: #040404 url(/wp-content/themes/aestheticcontours_com/images/gray-bg-2@2x.jpg) center center/cover no-repeat;
}

.bg-purple {
  background: #824B66 url(/wp-content/themes/aestheticcontours_com/images/purple-background@2x.jpg) center center/cover no-repeat;
}

.bg-purple-left {
  position: relative;
}
.bg-purple-left::after {
  content: "";
  background: #824B66 url(/wp-content/themes/aestheticcontours_com/images/purple-background@2x.jpg) center center/cover no-repeat;
  transform: translateX(-3rem) translateY(3rem);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .bg-purple-left::after {
    transform: translateX(-1rem) translateY(-1rem);
  }
}
.bg-purple-right {
  position: relative;
}
.bg-purple-right::after {
  content: "";
  background: #824B66 url(/wp-content/themes/aestheticcontours_com/images/purple-background@2x.jpg) center center/cover no-repeat;
  transform: translateX(3rem) translateY(3rem);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .bg-purple-right::after {
    transform: translateX(1rem) translateY(-1rem);
  }
}
.bg-purple-small-right {
  position: relative;
}
.bg-purple-small-right::after {
  content: "";
  background: #824B66 url(/wp-content/themes/aestheticcontours_com/images/purple-background@2x.jpg) center center/cover no-repeat;
  transform: translateX(2rem) translateY(2rem);
  width: 18.75rem;
  height: 18.75rem;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .bg-purple-small-right::after {
    transform: translateX(1rem) translateY(1rem);
  }
}
.bg-right {
  position: relative;
  z-index: 2;
  transform: translateX(100px);
}

.bg-left {
  position: relative;
  z-index: 2;
  transform: translateX(-100px);
}

@media screen and (max-width: 1024px) {
  .bg-right {
    transform: translateX(0) translateY(0);
  }
  .bg-left {
    transform: translateX(0) translateY(0);
  }
}
.bg-carecredit {
  background: #393E47 url(/wp-content/themes/aestheticcontours_com/images/care-credit-bg.jpg) center center/cover no-repeat;
  background-position: top center;
}

.painting-bg {
  background: url(/wp-content/themes/aestheticcontours_com/images/painting-bg-1.jpg) left top no-repeat, url(/wp-content/themes/aestheticcontours_com/images/painting-bg-2.jpg) right top no-repeat;
  background-size: contain;
}
@media screen and (max-width: 767px) {
  .painting-bg {
    background: url(/wp-content/themes/aestheticcontours_com/images/painting-bg-1.jpg) center no-repeat;
    background-size: cover;
  }
}

.z-1 {
  z-index: -1;
}

.z2 {
  z-index: 2;
}

.z5 {
  z-index: 5;
}

/* container module: core */
.container {
  position: relative;
}

/* gap module: core */
.gutter-16 {
  gap: 1rem;
}

.gutter-sm,
.gutter-24 {
  gap: 1.5rem;
}

.gutter-30 {
  gap: 1.875rem;
}

@media screen and (max-width: 767px) {
  .gutter-sm,
.gutter-24,
.gutter-30 {
    gap: 1rem;
  }
}

.gutter-col-width {
  gap: 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .gutter-col-width {
    gap: 4.75rem;
  }
}

/* columns module: core */
.grid,
[class^=grid-],
[class*=" grid-"] {
  display: grid;
}

.grid-2-col {
  grid-template-columns: 1fr 1fr;
}

.grid-2-col-5-7 {
  grid-template-columns: 5fr 7fr;
}

.grid-2-col-7-5 {
  grid-template-columns: 7fr 5fr;
}

.grid-3-col {
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 1024px) {
  .grid-3-col {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-4-col {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: 1024px) {
  .grid-4-col {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-5-col {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: 1024px) {
  .grid-5-col {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-2-col {
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1024px) {
  .grid-1-col-tablet {
    grid-template-columns: 1fr;
  }
  .grid-2-col-tablet {
    grid-template-columns: 1fr 1fr;
  }
  .grid-3-col-tablet {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .grid-2-col,
.grid-2-col-5-7,
.grid-2-col-7-5,
.grid-3-col,
.grid-4-col {
    grid-template-columns: 1fr;
  }
}
.grid-no-stretch {
  align-items: flex-start;
}

.grid-vertically-center-children > * {
  align-self: center;
  justify-self: center;
}

@media screen and (max-width: 767px) {
  .grid-2-col-mobile {
    grid-template-columns: 1fr 1fr;
  }
}
/* inverted module: core */
.inverted {
  color: #ffffff;
}
.inverted a {
  color: #932056;
}
.inverted a:hover {
  color: #ffffff;
}
.inverted nav a,
.inverted .nav a {
  color: #ffffff;
}
.inverted nav a:hover,
.inverted .nav a:hover {
  color: #932056;
}
.inverted .nav-vertical li::before {
  background-color: #ffffff !important;
}

.margin-bottom-none {
  margin-bottom: 0;
}

/* padding module: core */
.padding-md {
  padding: 3.125vw;
}
@media screen and (max-width: 767px) {
  .padding-md {
    padding: 24px;
  }
}
@media screen and (min-width: 1921px) {
  .padding-md {
    padding: 3.75rem;
  }
}

.padding-lg {
  padding: 4.1666666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-lg {
    padding: 5rem;
  }
}

.padding-xl {
  padding: 7.2916666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-xl {
    padding: 8.75rem;
  }
}

.padding-xxl {
  padding: 10.4166666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-xxl {
    padding: 12.5rem;
  }
}

.padding-top-md {
  padding-top: 2.6041666667vw;
}
@media screen and (max-width: 767px) {
  .padding-top-md {
    padding-top: 24px;
  }
}
@media screen and (min-width: 1921px) {
  .padding-top-md {
    padding-top: 3.125rem;
  }
}

.padding-bottom-md {
  padding-bottom: 2.6041666667vw;
}
@media screen and (max-width: 767px) {
  .padding-bottom-md {
    padding-bottom: 24px;
  }
}
@media screen and (min-width: 1921px) {
  .padding-bottom-md {
    padding-bottom: 3.125rem;
  }
}

.padding-h-lg {
  padding-left: 4.1666666667vw;
  padding-right: 4.1666666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-h-lg {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.padding-v-xl {
  padding-top: 7.2916666667vw;
  padding-bottom: 7.2916666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-v-xl {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
}

.padding-top-xl {
  padding-top: 7.2916666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-top-xl {
    padding-top: 8.75rem;
  }
}

.padding-bottom-none {
  padding-bottom: 0 !important;
}

.padding-top-none {
  padding-top: 0 !important;
}

.section {
  width: 100%;
}
.section > * {
  margin: auto;
}
.section > :not([class^=width-]):not([class*=" width-"]) {
  max-width: 1640px;
}

/* sr-only module: core */
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

@media screen and (min-width: 768px) {
  .sr-and-mobile-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
}
@media screen and (min-width: 1201px) {
  .sr-and-above-tablet {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
}
@media screen and (min-width: 1367px) {
  .sr-and-above-laptop {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
}
/* svg module: core */
#svg {
  top: 0;
  left: 0;
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;
  overflow: hidden;
  z-index: -1;
}

/* width module: core */
[class^=width-],
[class*=" width-"] {
  margin-left: auto;
  margin-right: auto;
}

.width-md {
  max-width: 33.75rem;
}

.width-xl {
  max-width: 83rem;
}

.width-full {
  max-width: 100%;
}

.width-content {
  max-width: max-content !important;
}

/* base module: theme */
p,
h4,
h5,
h6,
blockquote,
ul,
ol,
table {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
p + .button, p + .button-icon,
h4 + .button,
h4 + .button-icon,
h5 + .button,
h5 + .button-icon,
h6 + .button,
h6 + .button-icon,
blockquote + .button,
blockquote + .button-icon,
ul + .button,
ul + .button-icon,
ol + .button,
ol + .button-icon,
table + .button,
table + .button-icon {
  margin-top: 2.49375rem;
}
@media screen and (max-width: 767px) {
  p + .button, p + .button-icon,
h4 + .button,
h4 + .button-icon,
h5 + .button,
h5 + .button-icon,
h6 + .button,
h6 + .button-icon,
blockquote + .button,
blockquote + .button-icon,
ul + .button,
ul + .button-icon,
ol + .button,
ol + .button-icon,
table + .button,
table + .button-icon {
    margin-top: 1.875rem;
  }
}
@media screen and (max-width: 767px) {
  p,
h4,
h5,
h6,
blockquote,
ul,
ol,
table {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

h2,
.typography-heading-container:not(:first-child) {
  margin-top: 4.25rem;
}

h2 {
  margin-bottom: -0.5rem;
}
@media screen and (max-width: 767px) {
  h2 {
    margin-top: 3rem;
    margin-bottom: -0.375rem;
  }
}
h2 span {
  margin-bottom: -0.5625rem;
}
h2 + div {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  h2:first-child {
    margin-top: -1rem;
  }
  h2:first-child span {
    margin-top: 0.5625rem;
  }
  h2:last-child {
    margin-bottom: -1rem;
  }
}

h2 + p.typography-heavy,
h3 + p.typography-heavy,
.typography-heading-container + p.typography-heavy {
  padding-right: 13%;
}
@media screen and (max-width: 767px) {
  h2 + p.typography-heavy,
h3 + p.typography-heavy,
.typography-heading-container + p.typography-heavy {
    padding-right: 0;
  }
}

blockquote:not(figure blockquote) {
  margin-left: 0;
  color: #5c5c5c;
  position: relative;
}
blockquote:not(figure blockquote)::before {
  display: block;
  width: 0.375rem;
  height: 100%;
  position: absolute;
  background: #932056;
  content: "";
  transform: translateX(-1.7em);
}

blockquote:not(figure blockquote) {
  padding-left: 2.8125rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#guide {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
#guide > div {
  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 0 calc(6.3vw + 1rem);
  box-sizing: border-box;
  max-width: 1920px;
  outline: 3px solid red;
}
#guide > div > div {
  height: 100%;
  background: rgba(0, 50, 255, 0.3);
  outline: 1px solid rgba(0, 0, 255, 0.3);
  outline-offset: -1px;
}

@media screen and (min-width: 1920px) {
  #guide > div {
    max-width: 1920px;
    padding: 0 8.5625rem;
  }
}
.overflow-hidden {
  overflow: hidden;
}

.hori-center {
  align-items: center;
}

.color-accent {
  color: #932056;
}

.column-reverse {
  display: -ms-flex;
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (max-width: 767px) {
  .column-reverse-mobile {
    display: -ms-flex;
    display: flex;
    flex-direction: column-reverse;
  }
}
.location {
  position: relative;
  margin-bottom: 2.5rem;
}
.location address {
  background-color: #000000;
  position: absolute;
  bottom: -1.875rem;
  left: 0;
  right: 0;
  width: 60%;
  padding: 3.125rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.location address h3 {
  font-size: 1.375rem;
  color: #932056;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.08em;
}
.location address h5 {
  font-size: 1.375rem;
  color: #932056;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.08em;
}
.location address a {
  color: #ffffff;
  font-weight: 300;
}

@media screen and (max-width: 1024px) {
  .location {
    margin: 10rem 0;
  }
  .location address {
    bottom: -7.5rem;
  }
}
@media screen and (max-width: 767px) {
  .location {
    margin: 6.25rem 0;
  }
  .location address {
    bottom: -8.75rem;
  }
}
.carecredit-logo {
  height: 2.8125rem;
}
.carecredit-logo a {
  display: block;
}
.carecredit-logo svg {
  height: 2.8125rem;
}

@media screen and (max-width: 767px) {
  body .uwy.userway_p5 .uai {
    bottom: 40px !important;
  }
}
.relative {
  position: relative;
}

.sticky {
  position: sticky;
  top: 0;
}

.gallery-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1.875rem 0;
}

.patient-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.875rem 0;
}

.patient-info {
  margin-top: 1.25rem;
}

.wpcf7-form p:first-of-type {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 767px) {
  .wpcf7-form p:first-of-type {
    grid-template-columns: 1fr;
  }
}

.gallery-listing__wrapper .case-box__images.double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
}
@media screen and (max-width: 767px) {
  .gallery-listing__wrapper .case-box__images.double {
    padding: 0 30px;
  }
}
.gallery-listing__wrapper .case-box__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  border: 1px solid #000;
}
@media screen and (max-width: 767px) {
  .gallery-listing__wrapper .case-box__wrapper {
    margin: 0 10px;
  }
}
.gallery-listing__wrapper .c-btn__wrapper {
  margin-bottom: 1.5rem;
}

.patient-listing__wrapper .case-box__images.double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.gallery-home > div {
  display: flex;
  flex-direction: column;
}
.gallery-home .bg-gray-3 {
  padding: unset;
  flex-grow: 1;
}

.term-brachioplasty .gallery-listing__wrapper .case-box__images.double {
  grid-template-columns: 1fr;
}
@media screen and (max-width: 767px) {
  .term-brachioplasty .gallery-listing__wrapper .case-box__images.double {
    padding: 0;
  }
}
.term-brachioplasty .gallery-listing__wrapper .case-box__images.double > div:first-child {
  margin-top: 2.25rem;
}
.term-brachioplasty .gallery-listing__wrapper .case-box__images.double > div:last-child {
  margin-bottom: 1.875rem;
}
.term-brachioplasty .gallery-listing__wrapper .case-box__images.double img {
  margin: 0;
}

.meet-doctor .grid-2-col .google-rating {
  transform: scale(0.5);
  position: absolute;
  right: 0;
  bottom: 0;
}
@media screen and (min-width: 1442px) {
  .meet-doctor .grid-2-col .google-rating {
    right: 150px;
  }
}
@media screen and (max-width: 767px) {
  .meet-doctor .grid-2-col .google-rating {
    position: relative;
    transform-origin: 0;
  }
}

[data-aos=fade-in-btm] {
  opacity: 0;
  transform: translateY(10vh);
  transition: opacity 0.7s ease 0.14s, transform 0.595s cubic-bezier(0, 1.1, 0.985, 0.985);
}

.js-intersection-observed[data-aos=fade-in-btm] {
  opacity: 1;
  transform: translateY(0);
}

[data-aos=fade-in] {
  opacity: 0;
  transition: opacity 0.7s ease 0.14s;
}

.js-intersection-observed[data-aos=fade-in] {
  opacity: 1;
}

[data-aos=fade-in-collage] img {
  transition: opacity 0.7s ease 0.14s;
  opacity: 0;
}
[data-aos=fade-in-collage] img:nth-child(1) {
  transition-delay: 0.1s;
}
[data-aos=fade-in-collage] img:nth-child(2) {
  transition-delay: 0s;
}
[data-aos=fade-in-collage] img:nth-child(3) {
  transition-delay: 0.3s;
}
[data-aos=fade-in-collage] img:nth-child(4) {
  transition-delay: 0.6s;
}
[data-aos=fade-in-collage] img:nth-child(5) {
  transition-delay: 0.4s;
}
[data-aos=fade-in-collage] img:nth-child(6) {
  transition-delay: 0.2s;
}
[data-aos=fade-in-collage] img:nth-child(7) {
  transition-delay: 0.5s;
}

.js-intersection-observed[data-aos=fade-in-collage] img {
  opacity: 1;
}

/* banner module: theme */
.home-banner {
  max-height: 100vh;
  height: 100vh;
}

.swiper {
  height: 100%;
}

.banner-image {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: block;
}

@media screen and (max-width: 767px) {
  .home-banner .banner-1 {
    background-position-x: 20%;
  }
  .home-banner .banner-2 {
    background-position-x: 22%;
  }
  .home-banner .banner-3 {
    background-position-x: 84%;
  }
}
/* bg module: theme */
.bg-cover-photo {
  background-color: #5c5c5c;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-tinted::after,
.bg-tinted-less::after,
.bg-tinted-more::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: rgba(0, 0, 0, 0.3);
}

.bg-tinted-less::after {
  background: rgba(0, 0, 0, 0.1);
}

.bg-tinted-more::after {
  background: rgba(0, 0, 0, 0.5);
}

.hero_swiper {
  touch-action: none !important;
}

.bxa_carousel .bxa_carousel_container {
  top: 150px;
  right: 0;
  margin-left: auto;
  z-index: 1;
  position: absolute;
  width: calc(90% - (50% - 90px));
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .bxa_carousel .bxa_carousel_container {
    position: relative;
    width: auto;
    top: auto;
  }
}
.bxa_carousel .swiper-container {
  width: 520px;
  height: 300px;
}
@media screen and (max-width: 767px) {
  .bxa_carousel .swiper-container {
    width: 390px;
    height: auto;
  }
}
.bxa_carousel .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.4s ease-in;
}
.bxa_carousel .swiper-slide-active {
  opacity: 1;
}
.bxa_carousel .swiper-slide img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
.bxa_carousel .bxa_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bxa_carousel .swiper-button-next, .bxa_carousel .swiper-button-prev {
  position: relative;
  top: initial;
  left: initial;
  right: initial;
  margin: 0 0.3125rem;
}
@media screen and (max-width: 1024px) {
  .bxa_carousel .swiper-button-next, .bxa_carousel .swiper-button-prev {
    display: block;
  }
}

.gallery-case-banner {
  max-height: 10vh;
}

/* photo-gallery-link module: theme */
.photo-gallery-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 7.5vw;
  bottom: 3.375rem;
  z-index: 5;
  width: 6.125rem;
  height: 6.125rem;
}
.photo-gallery-link svg {
  width: 6.125rem;
  height: 6.125rem;
  display: block;
  position: absolute;
  fill: #ffffff;
}
.photo-gallery-link span {
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  display: block;
  margin: -0.3125rem -0.375rem 0 0;
  width: 3.75rem;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  transition: color 0.2s ease-in;
}
.photo-gallery-link:hover span {
  color: #932056;
}

@media screen and (max-width: 767px) {
  .photo-gallery-link {
    display: none;
  }
  .photo-gallery-link span {
    font-size: 1rem;
  }
}
/* bg module: theme */ /* bio module: theme */ /* button module: core */
.button,
.button-icon {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  position: relative;
  min-height: 2.5rem;
  align-items: center;
  justify-content: center;
  z-index: 0;
  color: #000000;
  font-weight: 500;
  transition: color 0.2s ease-in;
}
.button::before, .button::after,
.button-icon::before,
.button-icon::after {
  display: block;
  content: "";
  position: absolute;
  background-color: #932056;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  z-index: -1;
  transition: transform 0.2s ease-in;
}
.button::before,
.button-icon::before {
  top: 0;
}
.button::after,
.button-icon::after {
  bottom: 0;
}
.button:hover,
.button-icon:hover {
  color: #932056;
}
.button:hover::before,
.button-icon:hover::before {
  transform: translateY(-6px);
}
.button:hover::after,
.button-icon:hover::after {
  transform: translateY(6px);
}

@media screen and (max-width: 767px) {
  .padding-section-content .button:last-child {
    margin-bottom: 24px;
  }
}

.button-icon svg {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
}

.inverted a.button,
.inverted a.button-icon,
a.button.inverted,
a.button-icon.inverted {
  color: #ffffff;
}
.inverted a.button svg,
.inverted a.button-icon svg,
a.button.inverted svg,
a.button-icon.inverted svg {
  fill: #ffffff;
}
.inverted a.button:hover,
.inverted a.button-icon:hover,
a.button.inverted:hover,
a.button-icon.inverted:hover {
  color: #932056;
}

.button-round {
  border: 1px solid #ffffff;
  border-radius: 25px;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 0.3125rem 2.5rem;
  min-height: 2.5rem;
  min-width: 6.25rem;
  align-items: center;
  justify-content: center;
  z-index: 0;
  color: #ffffff;
  font-weight: 500;
  transition: all 0.2s ease-in;
}
.button-round::before {
  content: "";
  display: block;
  background: rgb(147, 32, 86);
  background: linear-gradient(90deg, rgb(147, 32, 86) 0%, rgb(93, 24, 53) 100%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: transform 0.2s ease-in;
  transform: translateX(-100%);
}
.button-round:hover {
  color: #ffffff;
  border-color: rgb(147, 32, 86);
}
.button-round:hover::before {
  transform: translateX(0);
}

.button-gray {
  background-color: #363636;
  color: #ffffff;
  font-weight: 500;
  padding: 1.5625rem 2.5rem;
  transition: color 0.2s ease-in;
  overflow: hidden;
  position: relative;
  min-height: 11.875rem;
  box-sizing: border-box;
}
.button-gray > * {
  color: #ffffff;
  position: relative;
}
.button-gray::before {
  content: "";
  background-color: #932056;
  height: 100%;
  width: 100%;
  transform: translateX(-98%);
  top: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  transition: transform 0.2s ease-in;
}
.button-gray:hover::before {
  transform: translateX(0);
}
@media screen and (max-width: 767px) {
  .button-gray {
    min-height: 8.125rem;
  }
}

input[type=submit] {
  background: none;
  outline: 0;
  border-top: 1px solid #932056;
  border-bottom: 1px solid #932056;
  padding: 1.25rem 0;
  margin: 0.625rem auto;
  transition: all 0.2s ease-in;
}
input[type=submit]:hover {
  padding: 1.875rem 0;
  margin: 0 auto;
}

.column-2 {
  column-count: 2;
  column-width: max-content;
}
@media screen and (max-width: 767px) {
  .column-2 {
    column-count: 1;
    column-width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .container-offset-left {
    padding-right: calc(3.75vw + 1rem);
    margin-right: calc(-3.75vw + -1rem);
    position: relative;
    z-index: 2;
  }
}

@media screen and (min-width: 768px) {
  .container-offset-right {
    padding-left: calc(3.75vw + 1rem) !important;
    margin-left: calc(-3.75vw + -1rem) !important;
    margin-top: 3.125vw;
    margin-bottom: 3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .container-offset-right {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
}
@media screen and (max-width: 767px) {
  .container-offset-right.padding-section-content {
    padding-bottom: 0 !important;
  }
}

.container-min-height {
  display: flex;
  flex-direction: column;
}

.padding-default .container-offset-left,
.padding-default .container-offset-right {
  padding-left: 0;
  padding-right: 0;
}

.container-before-after-sample {
  position: relative;
  box-sizing: border-box;
}
.container-before-after-sample h2 {
  font-family: "futura-pt", sans-serif;
  font-size: 3.125rem;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.28;
  font-size: 4.375rem;
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .container-before-after-sample h2 {
    font-size: 2.25rem;
    line-height: 1.25;
  }
}
.container-before-after-sample h2 span {
  margin: 0 0 -2.125rem 0.375rem;
}
@media screen and (max-width: 767px) {
  .container-before-after-sample h2 {
    font-size: 3.375rem;
  }
  .container-before-after-sample h2 span {
    margin-bottom: -0.625rem;
  }
}
.container-before-after-sample .hr {
  max-width: 16.25rem;
  margin: 1.875rem auto 2.5rem auto;
}
@media screen and (max-width: 767px) {
  .container-before-after-sample .hr {
    margin: 0.625rem auto 1.25rem auto;
  }
}
.container-before-after-sample h2,
.container-before-after-sample .hr,
.container-before-after-sample figcaption {
  position: relative;
  z-index: 3;
}
.container-before-after-sample figure {
  display: block;
  width: 100%;
}
.container-before-after-sample figure img {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
}
.container-before-after-sample figure img:first-child {
  left: 0;
}
.container-before-after-sample figure img + img {
  right: 0;
}
.container-before-after-sample figure figcaption {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 2;
}
@media screen and (max-width: 767px) {
  .container-before-after-sample figure figcaption {
    line-height: 1.25;
  }
}
.container-before-after-sample .read-more {
  right: -6.25rem;
  bottom: -6.25rem;
}
@media screen and (max-width: 1366px) {
  .container-before-after-sample .read-more {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    display: block;
    text-transform: uppercase;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    padding: 1.25rem 2.25rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
    color: #ffffff;
    background: #000000;
    width: auto;
    height: auto;
    margin-top: 1.25rem;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    display: block;
    text-transform: uppercase;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    padding: 1.25rem 2.25rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
    color: #000000;
    background: #932056;
    position: static;
    right: auto;
    bottom: auto;
    margin: 1.875rem auto;
  }
  .container-before-after-sample .read-more:hover {
    background: #932056;
    color: #000000;
  }
  .container-before-after-sample .read-more svg,
.container-before-after-sample .read-more .read-more__circle,
.container-before-after-sample .read-more .read-more__text,
.container-before-after-sample .read-more .read-more__arrow {
    display: none !important;
  }
  .container-before-after-sample .read-more:hover {
    background: #000000;
    color: #ffffff;
  }
}
@media screen and (max-width: 767px) {
  .container-before-after-sample .read-more {
    margin-bottom: 0.625rem;
  }
}

.container-wellness {
  display: grid;
  grid-template-columns: 55fr 41fr;
  position: relative;
  z-index: 5;
  padding: 5.2083333333vw 0 3.9583333333vw 0;
}
@media screen and (max-width: 767px) {
  .container-wellness {
    display: block;
  }
}
.container-wellness .bg-gray-2 {
  position: relative;
  bottom: -7.9166666667vw;
}
.container-wellness .nav ul {
  gap: 2.7083333333vw;
}
@media screen and (min-width: 1921px) {
  .container-wellness {
    padding: 6.25rem 0 4.75rem 0;
  }
  .container-wellness .bg-gray-2 {
    bottom: -9.5rem;
  }
  .container-wellness .nav ul {
    gap: 3.25rem;
  }
}
@media screen and (max-width: 1600px) {
  .container-wellness {
    grid-template-columns: 60fr 40fr;
    gap: 0;
  }
  .container-wellness .bg-gray-2 {
    padding: 3.9583333333vw;
  }
  .container-wellness .nav ul {
    gap: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .container-gallery-promo .container {
    padding: 8.9583333333vw 4.0625vw;
  }
}
@media screen and (min-width: 1921px) {
  .container-gallery-promo .container {
    padding: 10.75rem 4.875rem;
  }
}
.container-gallery-promo .bg-cover-photo {
  background-image: url(/wp-content/themes/aestheticcontours_com/images/bg-gallery-promo.jpg);
}
@media screen and (min-width: 768px) {
  .container-gallery-promo .bg-cover-photo {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .container-gallery-promo .bg-cover-photo {
    width: 100%;
    height: 60vw;
  }
}
.container-gallery-promo h2 {
  font-family: "futura-pt", sans-serif;
  font-size: 3.125rem;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.28;
  font-size: 4.375rem;
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .container-gallery-promo h2 {
    font-size: 2.25rem;
    line-height: 1.25;
  }
}
@media screen and (min-width: 768px) {
  .container-gallery-promo h2 .typography-script {
    display: block;
    position: absolute;
    margin: 2rem 0 0 -4.25rem;
  }
}
@media screen and (max-width: 767px) {
  .container-gallery-promo h2 {
    padding: 0 24px;
    margin-top: 30px;
  }
  .container-gallery-promo h2 .typography-script {
    display: inline !important;
  }
}
@media screen and (max-width: 767px) {
  .container-gallery-promo .nav {
    margin: 24px;
  }
}
.container-gallery-promo .nav .hr {
  margin-top: 1.09375vw;
  margin-bottom: 0.6770833333vw;
}
@media screen and (min-width: 1921px) {
  .container-gallery-promo .nav .hr {
    margin-top: 1.3125rem;
    margin-bottom: 0.8125rem;
  }
}
.container-gallery-promo .nav ul {
  gap: 2.0833333333vw;
}
@media screen and (min-width: 1921px) {
  .container-gallery-promo .nav ul {
    gap: 2.5rem;
  }
}

.container-column-menu {
  margin-top: 1rem;
}
.container-column-menu a {
  display: block;
}
.container-column-menu h2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media screen and (min-width: 768px) {
  .container-column-menu h2 {
    min-height: 8rem;
  }
}
@media screen and (max-width: 1200px) {
  .container-column-menu h2 {
    font-size: 2.25rem;
    line-height: 1.25;
  }
}
@media screen and (max-width: 767px) {
  .container-column-menu > div:not(:first-child) > h2 {
    margin-top: 24px;
  }
}
.container-column-menu img {
  margin: 2.25rem auto 1.875rem auto;
}

.container-tiled-corner-box {
  margin: 3.125rem 3.125rem 3.125rem 1.875rem;
}
@media screen and (max-width: 767px) {
  .container-tiled-corner-box {
    margin: 30px;
  }
  .container-tiled-corner-box .padding-xl2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.container-tiled-corner-box .container-tiled-corner-box__tiles {
  position: relative;
}
.container-tiled-corner-box .container-tiled-corner-box__tiles > * {
  position: relative;
  z-index: 1;
}
.container-tiled-corner-box .container-tiled-corner-box__tiles::before, .container-tiled-corner-box .container-tiled-corner-box__tiles::after {
  content: "";
  display: block;
  position: absolute;
  width: 80%;
  height: 78%;
  z-index: 0;
  background: #f2f2f2 url(/wp-content/themes/aestheticcontours_com/images/bg-tile-light-sm.jpg) center center/cover no-repeat;
  opacity: 0.4;
}
.container-tiled-corner-box .container-tiled-corner-box__tiles::before {
  top: -3.125rem;
  right: -3.125rem;
}
.container-tiled-corner-box .container-tiled-corner-box__tiles::after {
  bottom: -3.125rem;
  left: -3.125rem;
}
@media screen and (max-width: 767px) {
  .container-tiled-corner-box .container-tiled-corner-box__tiles::before {
    top: -1.875rem;
    right: -1.875rem;
  }
  .container-tiled-corner-box .container-tiled-corner-box__tiles::after {
    bottom: -1.875rem;
    left: -1.875rem;
  }
}

.container-border-left,
.container-border-right {
  position: relative;
}
.container-border-left::before,
.container-border-right::before {
  content: "";
  display: block;
  width: 0.375rem;
  height: 100%;
  position: absolute;
  background: #932056;
  top: 0;
}
@media screen and (max-width: 767px) {
  .container-border-left::before,
.container-border-right::before {
    display: none;
  }
  .container-border-left.padding-section-content,
.container-border-right.padding-section-content {
    padding: 0 24px;
  }
  .container-border-left:first-child,
.container-border-right:first-child {
    margin-bottom: 24px;
  }
  .container-border-left:not(:first-child),
.container-border-right:not(:first-child) {
    margin-top: 24px;
  }
}

.container-border-left::before {
  left: 0;
}
@media screen and (max-width: 767px) {
  .container-border-left {
    border-left: 0.375rem solid #932056;
  }
}

.container-border-right::before {
  right: 0;
}
@media screen and (max-width: 767px) {
  .container-border-right {
    border-right: 0.375rem solid #932056;
  }
}

.container-box-sizing {
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .container-box-sizing {
    padding-bottom: 5.625rem;
  }
}
.container-box-sizing svg {
  display: block;
  margin: 0 auto 3.125rem auto;
  width: 100%;
  height: 2.25rem;
  max-width: 17rem;
}

.container-review-tiles {
  list-style: none;
  padding: 0.875rem 0 5.2083333333vw 0;
}
@media screen and (min-width: 1921px) {
  .container-review-tiles {
    padding-bottom: 6.25rem;
  }
}
.container-review-tiles a {
  display: block;
  background: #363636;
  padding: 2.125rem 2rem 2.875rem 2.625rem;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .container-review-tiles a {
    padding: 32px 24px;
  }
}
.container-review-tiles a svg {
  display: block;
  width: 4.0625rem;
  height: 1.25rem;
  margin: 0 0 0.375rem 0;
  fill: #932056;
  transition: fill 0.2s ease-in;
}
.container-review-tiles a span:not(.sr-only) {
  display: block;
  font-size: 1.625rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 1.875rem;
  color: #ffffff;
  transition: color 0.2s ease-in;
}
@media screen and (min-width: 768px) {
  .container-review-tiles a span:not(.sr-only) {
    font-size: 1.25rem;
    margin: 0rem auto 0.75rem auto;
  }
}
.container-review-tiles a::after {
  display: block;
  content: "";
  position: absolute;
  background-color: #932056;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: transform 0.2s ease-in;
  transform: translateX(-100%);
}
.container-review-tiles a:hover svg {
  fill: #000000;
}
.container-review-tiles a:hover::after {
  transform: translateX(0);
}
.container-review-tiles .facebook-icon {
  position: absolute;
  bottom: -1.875rem;
  right: 0.9375rem;
  width: 5rem;
  height: 9.25rem;
}
.container-review-tiles .google-icon {
  position: absolute;
  bottom: -1.875rem;
  right: 0.9375rem;
  width: 8.8125rem;
  height: 8.9375rem;
}
.container-review-tiles .realself-icon {
  position: absolute;
  bottom: -1.875rem;
  right: 0.9375rem;
  width: 11.625rem;
  height: 8rem;
}
.container-review-tiles .yelp-icon {
  position: absolute;
  bottom: -1.875rem;
  right: 0.9375rem;
  width: 6.875rem;
  height: 9.25rem;
}

/* footer module: theme */
.footer {
  background: #000000;
  color: #ffffff;
}
.footer > * {
  margin: auto;
}
.footer a {
  color: #ffffff;
  font-weight: 300;
}
.footer a:hover {
  color: #932056;
}
.footer address {
  font-weight: 300;
}
.footer address h3 {
  font-size: 1.25rem;
  text-transform: uppercase;
}
.footer address h5 {
  font-size: 1.25rem;
  text-transform: uppercase;
}
.footer address a {
  display: block;
}
.footer__legal {
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  text-align: right;
}
.footer__legal ul {
  margin-left: auto;
  justify-content: flex-end;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  display: flex;
  text-transform: uppercase;
  margin-top: 0;
  margin-right: -0.25rem;
}
.footer__legal ul li:not(:last-child)::after {
  content: "|";
}
.footer__legal ul li a {
  padding: 0 0.25rem;
}
.footer__legal a {
  font-weight: 500;
}
.footer .logo img {
  width: 162px;
  margin-bottom: 5rem;
}
.footer .social {
  margin-bottom: 10rem;
}
.footer .social ul {
  justify-content: flex-end;
}
.footer .social ul a svg {
  fill: #ffffff;
}

@media screen and (max-width: 767px) {
  .footer {
    padding-bottom: 5.5rem;
  }
  .footer .logo {
    margin-top: 1rem;
  }
  .footer .social {
    margin: 3rem 0;
  }
  .footer .social ul {
    justify-content: flex-start;
  }
  .footer__legal {
    text-align: left;
  }
  .footer__legal ul {
    justify-content: flex-start;
  }
}
/* header module: theme */
.header {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  background: #5c5c5c;
  position: absolute;
  width: 100%;
}
.header .header__content {
  background: rgb(34, 30, 32);
  background: linear-gradient(0deg, rgba(34, 30, 32, 0) 0%, rgba(34, 30, 32, 0.5) 100%);
  position: relative;
  min-height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
}
.header a:not(.header__skip) {
  color: #ffffff;
  padding: 0.625rem;
}
.header a:not(.header__skip):hover {
  color: #932056;
}
.header .header__left {
  position: absolute;
  left: 2.3vw;
  display: flex;
  align-items: center;
}
.header .header__left a:not(.logo) {
  margin-left: 1.25rem;
}
.header .header__left .logo {
  padding: 1.25rem;
}
.header .header__left .logo img {
  height: 2.3125rem;
}
.header .header__left .logo img .logo__mobile {
  display: none;
}
.header .header__left .logo + a {
  margin-left: 0;
}
.header nav ul {
  display: flex;
  gap: 2.5rem;
  gap: 2.5vw;
}
.header nav ul a {
  font-weight: 300;
}
@media screen and (max-width: 1200px) {
  .header nav {
    display: none;
  }
}
.header .header__photo-gallery-link {
  position: absolute;
  text-align: center;
  bottom: 0;
}
.header .header__photo-gallery-link,
.header .header__photo-gallery-link a,
.header .header__photo-gallery-link svg {
  display: block;
  width: 6.125rem;
  height: 5.625rem;
}
.header .header__photo-gallery-link svg {
  fill: #ffffff;
}

.header-gallery {
  background: #363636 url(/wp-content/themes/aestheticcontours_com/images/bg-tile-nav.jpg) center center/cover no-repeat;
}

.gallery-titles h1 span {
  display: block;
  font-size: smaller;
}

.header__skip {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 0.375rem 1.25rem;
  position: fixed;
  top: 1.625rem;
  left: 3rem;
  transform: translateX(calc(-100% - 48px));
  height: 3rem;
  transition: transform 0.2s ease-in, color 0.2s ease-in;
  z-index: 7;
}
.header__skip:focus {
  transform: translateX(0);
  opacity: 1;
}
.header__skip svg {
  margin-left: 1rem;
  width: 2rem;
  height: 1.375rem;
  transition: fill 0.2s ease-in;
}
.header__skip:hover svg {
  fill: #932056;
}

@media screen and (max-width: 767px) {
  .header {
    position: fixed;
    z-index: 8;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.975);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    filter: drop-shadow(0 0 0.75rem rgba(30, 10, 0, 0.125));
    top: 0;
    height: 60px;
  }
  .header .header__content {
    min-height: 60px;
    background: none;
  }
  .header .header__left {
    left: 0;
  }
  .header .header__left .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: auto;
    padding: 0 16px;
  }
  .header .header__left .logo svg {
    width: 190px;
    height: 40px;
  }
  .header .header__left .logo svg .logo__mobile {
    display: block;
  }
  .header .header__left .logo svg .logo__desktop {
    display: none;
  }
  .header .header__left a:not(.logo) {
    display: none;
  }
}
hr, .hr {
  border: 0;
  background: #D6D6D6;
  height: 1px;
  margin: 2.5rem auto !important;
}

nav hr,
nav .hr,
.banner hr,
.banner .hr,
.quote hr,
.quote .hr,
.hr--short {
  max-width: 38.125rem;
  background-position: 1.625rem center;
}
nav hr:before,
nav .hr:before,
.banner hr:before,
.banner .hr:before,
.quote hr:before,
.quote .hr:before,
.hr--short:before {
  width: 2.125rem;
}
nav hr:after,
nav .hr:after,
.banner hr:after,
.banner .hr:after,
.quote hr:after,
.quote .hr:after,
.hr--short:after {
  width: calc(100% - 2.9375rem);
}

.hr-initials {
  position: relative;
  width: 100%;
  height: 3.25rem;
}
.hr-initials::before, .hr-initials::after {
  content: "";
  display: block;
  position: absolute;
  height: 0.0625rem;
  width: calc(50% - 4.75rem);
  background: #000000;
  top: 50%;
}
.hr-initials::before {
  left: 0;
}
.hr-initials::after {
  right: 0;
}
.hr-initials svg {
  width: 3.625rem;
  height: 3.25rem;
  position: absolute;
  display: block;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.hr-initials + h2 {
  margin-top: 1.875rem;
}

.inverted .hr-initials::before, .inverted .hr-initials::after,
.hr-initials.inverted::before,
.hr-initials.inverted::after {
  background: rgba(255, 255, 255, 0.3);
}
.inverted .hr-initials svg,
.hr-initials.inverted svg {
  fill: #ffffff;
}

nav hr:last-of-type:not(:first-child),
nav .hr:last-of-type:not(:first-child) {
  transform: rotate(180deg);
}

nav hr:last-of-type:not(:first-child),
nav .hr:last-child,
hr.hr--flipped,
.hr.hr--flipped {
  transform: rotate(180deg);
}

hr.inverted,
.hr.inverted,
.inverted hr,
.inverted .hr,
.banner hr,
.banner .hr {
  opacity: 1;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 30" style="transform:rotate(45deg);fill:white;"><rect width="1" height="30" /></svg>');
}
hr.inverted:before, hr.inverted:after,
.hr.inverted:before,
.hr.inverted:after,
.inverted hr:before,
.inverted hr:after,
.inverted .hr:before,
.inverted .hr:after,
.banner hr:before,
.banner hr:after,
.banner .hr:before,
.banner .hr:after {
  background: #ffffff;
}

.inverted .hr--faded {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 30" style="transform:rotate(45deg);fill:rgba(255,255,255,0.3);"><rect width="1" height="30" /></svg>');
}
.inverted .hr--faded:before, .inverted .hr--faded:after {
  background: rgba(255, 255, 255, 0.3);
}

@media screen and (min-width: 768px) {
  .img-full-height {
    height: 100%;
    object-fit: cover;
  }
}
.img-gutter-pull-right {
  width: calc(100% + 1rem);
  margin-right: -1rem;
}

/* logo module: theme */
.logo,
.logo img,
.logo svg {
  display: block;
}
.logo svg {
  transform: scale(0.999);
}

.margin-top-25 {
  margin-top: 1.5625rem !important;
}

.margin-top-45 {
  margin-top: 2.34375vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-45 {
    margin-top: 2.8125rem;
  }
}

.margin-bottom-66 {
  margin-bottom: 3.4375vw;
}
@media screen and (min-width: 1921px) {
  .margin-bottom-66 {
    margin-bottom: 4.125rem;
  }
}

.margin-top-70 {
  margin-top: 3.6458333333vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-70 {
    margin-top: 4.375rem;
  }
}

.margin-default {
  margin: 3.9583333333vw auto;
}
@media screen and (min-width: 1921px) {
  .margin-default {
    margin: 4.75rem auto;
  }
}
@media screen and (max-width: 767px) {
  .margin-default {
    margin: 24px auto;
  }
}

.margin-top-default {
  margin-top: 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-default {
    margin-top: 4.75rem;
  }
}

.margin-top-100 {
  margin-top: 5.2083333333vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-100 {
    margin-top: 6.25rem;
  }
}

.margin-top-125 {
  margin-top: 6.5104166667vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-125 {
    margin-top: 7.8125rem;
  }
}

@media screen and (max-width: 767px) {
  .banner + .margin-top-125 {
    margin-top: 0;
  }
}

.margin-bottom-100 {
  margin-bottom: 5.2083333333vw;
}
@media screen and (min-width: 1921px) {
  .margin-bottom-100 {
    margin-bottom: 6.25rem;
  }
}

.margin-bottom-125 {
  margin-bottom: 6.5104166667vw;
}
@media screen and (min-width: 1921px) {
  .margin-bottom-125 {
    margin-bottom: 7.8125rem;
  }
}

.margin-top-180 {
  margin-top: 9.375vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-180 {
    margin-top: 11.25rem;
  }
}

.margin-v-100 {
  margin-top: 5.2083333333vw;
  margin-bottom: 5.2083333333vw;
}
@media screen and (min-width: 1921px) {
  .margin-v-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
}

@media screen and (max-width: 767px) {
  .margin-mobile-top-25 {
    margin-top: 1.5625rem !important;
  }
  .margin-mobile-bottom-25 {
    margin-bottom: 1.5625rem !important;
  }
  .margin-mobile-top-45 {
    margin-top: 2.8125rem !important;
  }
  .margin-mobile-top-75 {
    margin-top: 4.6875rem !important;
  }
  .margin-mobile-top-100 {
    margin-top: 6.25rem !important;
  }
  .margin-mobile-bottom-45 {
    margin-bottom: 2.8125rem !important;
  }
  .margin-mobile-bottom-200 {
    margin-bottom: 12.5rem !important;
  }
}
.margin-auto {
  margin: auto;
}

@media screen and (min-width: 768px) {
  .margin-right-auto {
    margin-right: auto;
  }
  .margin-left-auto {
    margin-left: auto;
  }
}
@media screen and (max-width: 767px) {
  .margin-right-auto {
    margin: auto;
  }
  .margin-left-auto {
    margin: auto;
  }
}
/* menu module: theme */
#menu {
  background: #363636 url(/wp-content/themes/aestheticcontours_com/images/bg-tile-nav.jpg) center center/cover no-repeat;
  position: fixed;
  display: grid;
  grid-template-columns: 6.5fr 3.5fr;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.595s cubic-bezier(0, 1.1, 0.985, 0.985);
  overflow: auto;
}
@media screen and (max-width: 1920px) {
  #menu {
    grid-template-columns: 7fr 3fr;
  }
}
@media screen and (max-width: 1200px) {
  #menu {
    grid-template-columns: 1fr;
  }
  #menu > nav:first-child {
    padding-bottom: 12.5rem;
  }
}
#menu > nav:first-child {
  height: 100%;
  max-height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#menu > nav:first-child a {
  font-family: "futura-pt", sans-serif;
  font-size: 3.125rem;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.28;
  display: block;
  color: #a5a5a5;
  padding: 0.3rem 0.625rem;
}
@media screen and (max-width: 767px) {
  #menu > nav:first-child a {
    font-size: 2.25rem;
    line-height: 1.25;
  }
}
@media screen and (max-width: 1920px) {
  #menu > nav:first-child a {
    font-size: 2.5rem;
    line-height: 1.25;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child a {
    font-size: 1.875rem;
  }
}
#menu > nav:first-child a:focus, #menu > nav:first-child a:hover {
  color: #ffffff;
}
#menu > nav:first-child a:focus + ul {
  opacity: 1;
  z-index: 9;
}
@media screen and (min-width: 1025px) {
  #menu > nav:first-child .menu__home-link {
    display: none;
  }
  #menu > nav:first-child > ul {
    margin: 12vh 27vw 0 0;
    max-width: -moz-max-content;
    max-width: max-content;
    position: relative;
  }
  #menu > nav:first-child > ul button {
    display: none;
  }
  #menu > nav:first-child > ul ul {
    position: absolute;
    opacity: 0;
    left: calc(100% - 0.875rem);
    width: 30vw;
    max-width: 37.5rem;
    box-sizing: border-box;
    padding-left: 5vw;
    top: 0;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease-in;
  }
  #menu > nav:first-child > ul ul:focus-within {
    opacity: 1;
    z-index: 9;
  }
  #menu > nav:first-child > ul ul a {
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 2;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1025px) and (max-width: 767px) {
  #menu > nav:first-child > ul ul a {
    line-height: 1.25;
  }
}
@media screen and (min-width: 1025px) {
  #menu > nav:first-child > ul ul a svg {
    display: block;
    width: 2.625rem;
    height: 1.8125rem;
    fill: #ffffff;
    opacity: 0;
    transform: translateX(0.3125rem);
    transition: transform 0.2s ease-in, opacity 0.2s ease-in;
  }
}
@media screen and (min-width: 1025px) {
  #menu > nav:first-child > ul ul a:hover svg, #menu > nav:first-child > ul ul a:focus svg {
    transform: translateX(1.25rem);
    opacity: 1;
  }
}
@media screen and (min-width: 1025px) {
  #menu > nav:first-child > ul > li {
    padding-right: 1.875rem;
  }
  #menu > nav:first-child > ul > li:hover > a, #menu > nav:first-child > ul > li:focus-within > a {
    color: #ffffff;
  }
  #menu > nav:first-child > ul > li:hover::before, #menu > nav:first-child > ul > li:focus-within::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: 100%;
  }
  #menu > nav:first-child > ul > li:hover ul, #menu > nav:first-child > ul > li:focus-within ul {
    opacity: 1;
    z-index: 9;
  }
}
@media screen and (max-width: 1920px) {
  #menu > nav:first-child > ul > ul {
    padding-left: 1vw;
  }
  #menu > nav:first-child > ul > ul a {
    line-height: 1.25;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child {
    transition: none;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 50px;
    background-size: auto;
    background-position: 0 0;
    background-repeat: repeat-y;
  }
  #menu > nav:first-child a {
    color: #cccccc;
  }
  #menu > nav:first-child > ul {
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
  }
  #menu > nav:first-child > ul > li {
    position: relative;
  }
  #menu > nav:first-child > ul > li a {
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 2;
    width: calc(100% - 50px);
  }
}
@media screen and (max-width: 1024px) and (max-width: 767px) {
  #menu > nav:first-child > ul > li a {
    line-height: 1.25;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child > ul > li .menu__sub-menu-wrapper {
    overflow: hidden;
    height: 0;
    transition: height 0.2s ease-in;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child > ul > li ul {
    margin: 0;
    padding: 0 0 24px 24px;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }
  #menu > nav:first-child > ul > li ul a {
    text-transform: none;
  }
  #menu > nav:first-child > ul > li ul a svg {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child > ul > li button {
    background: transparent;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -8px;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #menu > nav:first-child > ul > li button:before, #menu > nav:first-child > ul > li button:after {
    display: block;
    content: "";
    width: 14px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    opacity: 0.5;
    transition: transform 0.2s ease-in, opacity 0.2s ease-in;
  }
  #menu > nav:first-child > ul > li button:after {
    transform: rotate(90deg);
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child > ul > li.menu__sub-menu-expanded > a {
    color: #ffffff;
  }
  #menu > nav:first-child > ul > li.menu__sub-menu-expanded ul {
    opacity: 1;
  }
  #menu > nav:first-child > ul > li.menu__sub-menu-expanded button:before {
    opacity: 1;
  }
  #menu > nav:first-child > ul > li.menu__sub-menu-expanded button:after {
    transform: rotate(0deg);
    opacity: 0;
  }
}
#menu aside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  opacity: 0;
  transition: opacity 0.8s ease 0.1s;
  position: relative;
}
#menu aside .company-info {
  transition: opacity 0.8s ease 0.4s;
  opacity: 0;
}
#menu aside .company-info .logo {
  margin-bottom: 3.125rem;
}
#menu aside .company-info .logo svg {
  width: 16.3125rem;
  height: 8.875rem;
}
#menu aside .social {
  margin-top: 3.125rem;
  transition: opacity 0.8s ease 0.6s;
  opacity: 0;
}
@media screen and (max-width: 1200px) {
  #menu aside {
    padding: 6.25rem 0;
  }
  #menu aside .social {
    position: static;
    margin-top: 1.875rem;
  }
}
@media screen and (max-width: 1024px) {
  #menu aside {
    transition: none;
    padding: 3.125rem 0;
  }
  #menu aside .company-info {
    transition: none;
  }
}

#menu-trigger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 1.25rem 0.375rem 0;
  position: fixed;
  top: 1.625rem;
  right: 3.1vw;
  background: transparent;
  z-index: 9;
  transition: background-color 0.2s ease;
  outline-color: #ffffff !important;
  width: 15.625rem;
  align-items: flex-end;
}
#menu-trigger svg {
  width: 3.75rem;
  height: 3rem;
  transition: fill 0.2s ease;
  fill: #ffffff;
  transform: scaleX(-1);
}
#menu-trigger svg rect {
  transition: all 0.3s ease;
  transform-origin: center;
}
#menu-trigger span {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 400;
  margin-left: 1.6875rem;
  margin-top: -0.625rem;
}
@media screen and (max-width: 767px) {
  #menu-trigger span {
    color: #000000;
    margin-left: 1.5625rem;
  }
}
#menu-trigger .phone-cta {
  position: absolute;
  right: 6rem;
}
#menu-trigger .phone-cta a {
  font-weight: bold;
  color: #fff;
}
@media screen and (max-width: 767px) {
  #menu-trigger {
    right: 0;
    top: 0;
    height: 60px;
  }
  #menu-trigger svg {
    fill: #000000;
  }
}
@media (hover: hover) {
  #menu-trigger:hover svg rect:first-child {
    width: 1.25rem;
  }
  #menu-trigger:hover svg rect:nth-child(2) {
    width: 1.6875rem;
  }
  #menu-trigger:hover svg rect:last-child {
    width: 2.125rem;
  }
  #menu-trigger:active svg {
    fill: #ffffff;
    transition-duration: 0s;
  }
}

@media screen and (min-width: 768px) {
  .js-menu-trigger-is-inverted #menu-trigger {
    background: #ffffff;
  }
  .js-menu-trigger-is-inverted #menu-trigger .phone-cta a {
    color: #000000;
  }
}
.js-menu-trigger-is-inverted #menu-trigger,
.js-menu-is-active #menu-trigger {
  outline-color: #000000 !important;
}
.js-menu-trigger-is-inverted #menu-trigger span,
.js-menu-is-active #menu-trigger span {
  color: #000000;
}
@media screen and (max-width: 1200px) {
  .js-menu-trigger-is-inverted #menu-trigger span,
.js-menu-is-active #menu-trigger span {
    color: #ffffff;
  }
}
.js-menu-trigger-is-inverted #menu-trigger svg,
.js-menu-is-active #menu-trigger svg {
  fill: #000000;
}
@media (hover: hover) {
  .js-menu-trigger-is-inverted #menu-trigger:hover svg,
.js-menu-is-active #menu-trigger:hover svg {
    fill: #932056;
  }
  .js-menu-trigger-is-inverted #menu-trigger:active svg,
.js-menu-is-active #menu-trigger:active svg {
    fill: #000000;
  }
}

.js-menu-is-active #menu {
  transform: translateX(0);
}
.js-menu-is-active #menu > nav:first-child {
  opacity: 1;
  transition-duration: 1s;
}
.js-menu-is-active #menu aside {
  opacity: 1;
}
.js-menu-is-active #menu aside .company-info,
.js-menu-is-active #menu aside .social {
  opacity: 1;
}
.js-menu-is-active #menu-trigger {
  background: transparent;
}
.js-menu-is-active #menu-trigger .phone-cta a {
  display: none;
}
.js-menu-is-active #menu-trigger svg rect:first-child {
  transform: rotate(45deg) translateY(1rem) translateX(0);
}
.js-menu-is-active #menu-trigger svg rect:nth-child(2), .js-menu-is-active #menu-trigger svg rect:last-child {
  transform: rotate(-45deg) translateY(-0.75rem) translateX(0.3125rem);
  width: 34px;
}
.js-menu-is-active #menu-trigger svg rect:last-child {
  opacity: 0;
}
@media (hover: hover) {
  .js-menu-is-active #menu-trigger:hover svg rect:first-child {
    width: 2.125rem;
  }
  .js-menu-is-active #menu-trigger:hover svg rect:nth-child(2) {
    width: 2.125rem;
  }
}
@media screen and (max-width: 1200px) {
  .js-menu-is-active #menu-trigger {
    background: #2f2f2f;
  }
  .js-menu-is-active #menu-trigger svg {
    fill: #ffffff;
  }
}

.js-viewport-frozen {
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .js-viewport-frozen .header,
.js-viewport-frozen main,
.js-viewport-frozen .footer,
.js-viewport-frozen .menu-mobile-cta {
    visibility: hidden;
  }
}

.menu-mobile-cta {
  display: none;
}
@media screen and (max-width: 767px) {
  .menu-mobile-cta {
    position: fixed;
    z-index: 8;
    left: 0;
    width: 100%;
    background: rgba(147, 32, 86, 0.975);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    filter: drop-shadow(0 0 0.75rem rgba(30, 10, 0, 0.125));
    display: block;
    bottom: 0;
  }
  .menu-mobile-cta ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  .menu-mobile-cta ul li {
    flex: 1;
  }
  .menu-mobile-cta ul li:not(:last-child) {
    border-right: 0.0625rem solid #ffffff;
  }
  .menu-mobile-cta ul a {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.875rem;
    color: #ffffff;
  }
  .menu-mobile-cta ul a:hover {
    color: #000000;
  }
}

#menu aside {
  position: sticky;
  top: 0;
}
#menu .submenu-title a {
  cursor: default;
  color: #ddd !important;
  font-size: 1.25rem !important;
  text-transform: uppercase !important;
}
#menu .submenu-title a svg {
  display: none !important;
}
#menu .menu-item-object-procedure {
  padding-left: 1rem;
}
#menu #menu__sub-menu-5 li:last-of-type {
  margin-bottom: 1.875rem !important;
}

.nav {
  padding: 0 0 0.625rem 0;
}
.nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 2;
  flex-wrap: wrap;
  text-align: center;
  margin: auto;
  padding: 1.6875rem 0;
  gap: 4.0625vw;
  text-transform: capitalize;
  font-size: 1rem;
  letter-spacing: 0;
}
@media screen and (max-width: 767px) {
  .nav ul {
    line-height: 1.25;
  }
}
@media screen and (min-width: 1921px) {
  .nav ul {
    gap: 4.875rem;
  }
}
@media screen and (max-width: 767px) {
  .nav ul {
    flex-direction: column;
  }
}
.nav ul a {
  padding: 0.625rem;
}
.nav .hr {
  margin: 0 auto;
}

.nav-vertical ul {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 2;
  text-align: center;
  text-transform: capitalize;
  padding: 3.75rem 1.875rem;
  font-size: 1rem;
}
@media screen and (max-width: 767px) {
  .nav-vertical ul {
    line-height: 1.25;
  }
}
.nav-vertical ul li {
  position: relative;
  padding: 0.625rem 1.25rem;
}

.padding-none {
  padding: 0 !important;
}

.padding-bottom-25 {
  padding-bottom: 1.3020833333vw !important;
}
@media screen and (min-width: 1921px) {
  .padding-bottom-25 {
    padding-bottom: 1.5625rem !important;
  }
}

.padding-top-30 {
  padding-top: 1.875rem !important;
}

.padding-v-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.padding-v-50 {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}
@media screen and (max-width: 767px) {
  .padding-v-50 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.padding-v-100 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}
@media screen and (max-width: 767px) {
  .padding-v-100 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.padding-bottom-50 {
  padding-bottom: 3.125rem;
}

.padding-66 {
  padding: 3.4375vw;
}
@media screen and (min-width: 1921px) {
  .padding-66 {
    padding: 4.125rem;
  }
}

.padding-default {
  padding: 3.9583333333vw;
}
@media screen and (max-width: 767px) {
  .padding-default {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
@media screen and (min-width: 1921px) {
  .padding-default {
    padding: 4.75rem;
  }
}

.padding-right-default {
  padding-right: 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .padding-right-default {
    padding-right: 4.75rem;
  }
}

.padding-left-default {
  padding-left: 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .padding-left-default {
    padding-left: 4.75rem;
  }
}

@media screen and (max-width: 767px) {
  [class^=grid-] > .padding-right-default, [class^=grid-] > .padding-left-default,
[class*=" grid-"] > .padding-right-default,
[class*=" grid-"] > .padding-left-default {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
}
.padding-top-100 {
  padding-top: 5.2083333333vw !important;
}
@media screen and (min-width: 1921px) {
  .padding-top-100 {
    padding-top: 6.25rem !important;
  }
}

.padding-bottom-100 {
  padding-bottom: 5.2083333333vw !important;
}
@media screen and (min-width: 1921px) {
  .padding-bottom-100 {
    padding-bottom: 6.25rem !important;
  }
}

.padding-thick {
  padding: calc(6.3vw + 1rem);
}

.padding-section-content {
  padding: 3.9583333333vw calc(6.3vw + 1rem);
}
@media screen and (max-width: 767px) {
  .padding-section-content {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
}
@media screen and (min-width: 1921px) {
  .padding-section-content {
    padding: 4.75rem 8.5625rem;
  }
}

@media screen and (max-width: 767px) {
  .padding-default > .padding-section-content:not([class^=container-border-]):not([class*=" container-border-"]):not([class^=bg-]):not([class*=" bg-"]) {
    padding: 0 !important;
  }
  img + .padding-section-content {
    margin-top: 24px;
  }
}
.padding-v-145 {
  padding-top: 7.5520833333vw;
  padding-bottom: 7.5520833333vw;
}
@media screen and (min-width: 1921px) {
  .padding-v-145 {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
}

.padding-v-150 {
  padding-top: 7.8125vw;
  padding-bottom: 7.8125vw;
}

.padding-top-170 {
  padding-top: 10.625rem;
}
@media screen and (max-width: 767px) {
  .padding-top-170 {
    padding-top: 50px;
  }
}

.padding-xl0 {
  padding: 6.25rem 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .padding-xl0 {
    padding: 6.25rem 4.75rem;
  }
}

.padding-xl2 {
  padding: 11.25rem 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .padding-xl2 {
    padding: 11.25rem 4.75rem;
  }
}

@media screen and (max-width: 1366px) {
  .padding-thick,
.padding-section-content {
    padding: 3.9583333333vw;
  }
}
@media screen and (min-width: 1921px) {
  .padding-section-content,
.padding-default {
    max-width: 1920px;
  }
  .padding-thick {
    padding: 8.5625rem;
  }
  .padding-v-150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
}
/* photo-gallery-link module: theme */ /* quote module: theme */ /* read-more-link module: theme */
@keyframes textSpin {
  from {
    transform: rotate(14deg);
  }
  to {
    transform: rotate(374deg);
  }
}
@media screen and (min-width: 768px) {
  .read-more {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .read-more,
.read-more svg,
.read-more .read-more__circle {
    display: block;
    transform-style: preserve-3d;
  }
  .read-more,
.read-more .read-more__circle {
    width: 12.5rem;
    height: 12.5rem;
  }
  .read-more svg,
.read-more .read-more__circle,
.read-more .read-more__text,
.read-more .read-more__arrow {
    position: absolute;
  }
  .read-more .read-more__circle {
    background: #932056;
    border-radius: 100%;
    transform: scale(0.93);
    transition: transform 0.2s ease-in;
  }
  .read-more .read-more__text {
    width: 10rem;
    height: 10rem;
    left: 1.25rem;
    top: 1.25rem;
    transform: rotate(14deg);
  }
  .read-more .read-more__arrow {
    width: 2.625rem;
    height: 1.8125rem;
    transition: transform 0.2s ease-in;
    transform: rotate(-45deg) translate(-0.3125rem, 7.25rem);
  }
  .read-more:hover .read-more__text {
    transform: none;
    animation: textSpin 8s linear infinite;
  }
  .read-more:hover .read-more__circle {
    transform: scale(1);
  }
  .read-more:hover .read-more__arrow {
    transform: rotate(-45deg) translate(0.5625rem, 7.25rem);
  }
}

@media screen and (max-width: 767px) {
  .read-more {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    display: block;
    text-transform: uppercase;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    padding: 1.25rem 2.25rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
    color: #ffffff;
    background: #000000;
    width: auto;
    height: auto;
    margin-top: 1.25rem;
  }
  .read-more:hover {
    background: #932056;
    color: #000000;
  }
  .read-more svg,
.read-more .read-more__circle,
.read-more .read-more__text,
.read-more .read-more__arrow {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .inverted .read-more {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    display: block;
    text-transform: uppercase;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    padding: 1.25rem 2.25rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
    color: #000000;
    background: #932056;
  }
  .inverted .read-more:hover {
    background: #000000;
    color: #ffffff;
  }
}
/* social module: theme */
.social ul {
  list-style: none;
  display: flex;
  padding: 0;
}
.social ul a {
  display: block;
  padding: 0.5625rem;
}
.social ul a svg {
  display: block;
  fill: #000000;
  width: 1.375rem;
  height: 1.375rem;
  transition: fill 0.2s ease-in;
}
.social ul a:hover svg {
  fill: #932056;
}
@media screen and (max-width: 767px) {
  .social ul {
    justify-content: center;
  }
}

/* svg module: theme */
.svg__fill-accent {
  fill: #932056;
}

.svg__fill-white {
  fill: #ffffff;
}

.svg__fill-gray-dark {
  fill: #272727;
}

.width-500 {
  max-width: 31.25rem;
}

.width-550 {
  max-width: 34.375rem;
}

.width-600 {
  max-width: 37.5rem;
}

.width-640 {
  max-width: 40rem;
}

.width-674 {
  max-width: 42.125rem;
}

.width-812 {
  max-width: 50.75rem;
}

.width-852 {
  max-width: 53.25rem;
}

.width-1088 {
  max-width: 68rem !important;
}

.width-1128 {
  max-width: 70.5rem !important;
}

.width-1330 {
  max-width: 83.125rem !important;
}

.width-1364 {
  max-width: 85.25rem;
}

.width-1640 {
  max-width: 102.5rem;
}

.width-1920 {
  max-width: 1920px;
}