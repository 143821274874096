.nav {
  padding: 0 0 10*$pxToRem 0;

  ul {
    @include util-flex-row-align-children-center;
    @include config-type-subheading;
    flex-wrap: wrap;
    text-align: center;
    margin: auto;
    padding: 27*$pxToRem 0;
    gap: 78*$pxToVw;
    text-transform: capitalize;
    font-size: 16*$pxToRem;
    letter-spacing: 0;

    @include media-lg-monitor-only {
      gap: 78*$pxToRem;
    }

    @include media-mobile-only {
      flex-direction: column;
    }

    a {
      padding: 10*$pxToRem;
    }
  }

  .hr {
    margin: 0 auto;
  }
}

.nav-vertical {
  ul {
    @include config-type-subheading;
    text-align: center;
    text-transform: capitalize;
    padding: 60*$pxToRem 30*$pxToRem;
    font-size: 16*$pxToRem;

    li {
      position: relative;
      padding: 10*$pxToRem 20*$pxToRem;

      // &:not(:first-child)::before {
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   top: 0;
      //   left: 50%;
      //   width: 100%;
      //   height: 1*$pxToRem;
      //   max-width: 150*$pxToRem;
      //   transform: translateX(-50%);
      //   background: $color-gray-4;
      // }
    }
  }
}
