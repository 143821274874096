/* banner module: theme */

.home-banner {
    max-height: 100vh;
    height: 100vh;
}

.swiper {
  height: 100%;
}

.banner-image {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: block;
}

@include media-mobile-only {
  .home-banner{
    .banner-1 {
      background-position-x: 20%;
    }
    .banner-2 {
      background-position-x: 22%;
    }
    .banner-3 {
      background-position-x: 84%;
    }
  }
}

/* bg module: theme */
.bg-cover-photo {
  background-color: #5c5c5c;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-tinted::after,
.bg-tinted-less::after,
.bg-tinted-more::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: rgba(0, 0, 0, 0.3);
}

.bg-tinted-less::after {
  background: rgba(0, 0, 0, 0.1);
}

.bg-tinted-more::after {
  background: rgba(0, 0, 0, 0.5);
}

.hero_swiper {
    touch-action: none !important;
}

// .swiper {
//   width: 100%;
//   height: 100%;
//   &-slide {
//     background-position: center;
//     background-size: cover;
//     img {
//       display: block;
//       width: 100%;
//     }
//   }
// }

.bxa_carousel {
  .bxa_carousel_container {
    top: 150px;
    right: 0;
    margin-left: auto;
    z-index: 1;
    position: absolute;
    width: calc(90% - (50% - 90px));
    overflow: hidden;
    @include media-mobile-only {
      position: relative;
      width: auto;
      top: auto;
    }
  }
  .swiper-container {
    width: 520px;
    height: 300px;
    @include media-mobile-only {
      width: 390px;
      height: auto;
    }
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
    transition: opacity .4s ease-in;
  }

  .swiper-slide-active {
    opacity: 1;
  }

  .swiper-slide img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .bxa_pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-button-next, .swiper-button-prev {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    margin: 0 5*$pxToRem;
  }

  @media screen and (max-width: 1024px) {
    .swiper-button-next, .swiper-button-prev {
        display: block;
    }
  }

}

.gallery-case-banner {
  max-height: 10vh;
}

/* photo-gallery-link module: theme */
.photo-gallery-link {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 7.5vw;
	bottom: 3.375rem;
	z-index: 5;
	width: 6.125rem;
	height: 6.125rem;
	svg {
		width: 6.125rem;
		height: 6.125rem;
		display: block;
		position: absolute;
		fill: #ffffff;
	}
	span {
		font-size: 0.875rem;
		letter-spacing: 0;
		line-height: 1.25rem;
		display: block;
		margin: -0.3125rem -0.375rem 0 0;
		width: 3.75rem;
		text-align: center;
		text-transform: uppercase;
		color: $color-white;
		transition: color 0.2s ease-in;
	}
	&:hover {
		span {
			color: $color-accent;
		}
	}
}
@media screen and (max-width: 767px) {
	.photo-gallery-link {
		span {
			font-size: 1rem;
		}
		display: none;
	}
}
