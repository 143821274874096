/* header module: theme */

.header {
  @include util-antialiasing-default;
  background: $color-gray-2; // Without this WAVE thinks header links are white on white which they are not
  position: absolute;
  width: 100%;

  .header__content {
    background: rgb(34,30,32);
    background: linear-gradient(0deg, rgba(34,30,32,0) 0%, rgba(34,30,32,.5) 100%);
    position: relative;
    min-height: 112*$pxToRem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
  }

  a:not(.header__skip) {
    color: $color-text-inverted;
    //text-transform: uppercase;
    padding: 10*$pxToRem;

    &:hover {
      color: $color-accent;
    }
  }

  .header__left {
    position: absolute;
    left: 2.3vw;
    display: flex;
    align-items: center;
    // margin-right: auto;

    a:not(.logo) {
      margin-left: 20*$pxToRem;
    }

    .logo {
      padding: 20*$pxToRem;

      img {
        height: 37*$pxToRem;

        .logo__mobile {
          display: none;
        }
      }

      & + a {
        margin-left: 0;
      }
    }
  }

  nav {
    ul {
      display: flex;
      gap: 40*$pxToRem;
      gap: 2.5vw;
      a {
        font-weight: 300;
      }
    }

    @include media-below(1200) {
      display: none;
    }
  }

  .header__photo-gallery-link {
    position: absolute;
    text-align: center;
    bottom: 0;
    &,
    a,
    svg {
      display: block;
      width: 98*$pxToRem;
      height: 90*$pxToRem;
    }

    svg {
      fill: $color-white;
    }
  }
}

.header-gallery {
  background: $color-gray-1 url(#{$imagesPath}bg-tile-nav.jpg) center center / cover no-repeat;
}

.gallery-titles h1 span {
  display: block;
  font-size: smaller;
}

.header__skip {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  padding: 6*$pxToRem 20*$pxToRem;
  position: fixed;
  top: 26*$pxToRem;
  left: 48*$pxToRem;
  transform: translateX(calc(-100% - 48px));
  height: 48*$pxToRem;
  transition: transform $transition-default,
              color $transition-default;
  z-index: 7;

  &:focus {
    transform: translateX(0);
    opacity: 1;
  }

  svg {
    margin-left: 16*$pxToRem;
    width: 32*$pxToRem;
    height: 22*$pxToRem;
    transition: fill $transition-default;
  }

  &:hover {
    svg {
      fill: $color-accent;
    }
  }
}

@include media-mobile-only {
  .header {
    @include config-fixed-menu($color-white);
    top: 0;
    height: 60px;

    .header__content {
      min-height: 60px;
      background: none;
    }

    .header__left {
      left: 0;

      .logo {
        @include util-align-children-center;
        height: 60px;
        width: auto;
        padding: 0 16px;

        svg {
          width: 190px;
          height: 40px;

          .logo__mobile {
            display: block;
          }

          .logo__desktop {
            display: none;
          }
        }
      }

      a:not(.logo) {
        display: none;
      }
    }
  }
}
