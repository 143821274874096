/* menu module: theme */

// Navigation Menu and Company Info modal that slides into view
#menu {
  background: $color-gray-1 url(#{$imagesPath}bg-tile-nav.jpg) center center / cover no-repeat;
  position: fixed;
  display: grid;
  grid-template-columns: 6.5fr 3.5fr;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%); // menu is translated off screen by default
  transition: transform $transition-aos-transform;
  overflow: auto;

  @include media-below-lg-monitor {
    grid-template-columns: 7fr 3fr;
  }

  @include media-below(1200) {
    grid-template-columns: 1fr;

    & > nav:first-child {
      padding-bottom: 200*$pxToRem;
    }
  }

  & > nav:first-child {
    height: 100%;
    max-height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      @include config-type-heading;
      display: block;
      color: $color-gray-3;
      padding: 0.3rem 0.625rem;

      @include media-below-lg-monitor {
        font-size: 40*$pxToRem;
        line-height: 1.25;
      }

      @include media-below-desktop {
        font-size: 30*$pxToRem;
      }

      &:focus,
      &:hover {
        color: $color-white;
      }

      &:focus + ul {
        opacity: 1;
        z-index: 9;
      }
    }

    @include media-desktop-only {
      .menu__home-link {
        display: none;
      }

      // First-level <ul>
      & > ul {
        margin: 12vh 27vw 0 0;
        max-width: -moz-max-content;
        max-width: max-content;
        position: relative;

        // hide mobile subnav expand/collapse button
        button {
          display: none;
        }

        // Nested <ul>
        ul {
          position: absolute;
          opacity: 0;
          left: calc(100% - #{14*$pxToRem});
          //margin-top: -84*$pxToRem;
          width: 30vw;
          max-width: 600*$pxToRem;
          box-sizing: border-box;
          padding-left: 5vw;
          //padding-top: 25*$pxToRem;
          top: 0;
          opacity: 0;
          z-index: -1;
          transition: opacity $transition-default;

          &:focus-within {
            opacity: 1;
            z-index: 9;
          }

          // Second-level <a>
          a {
            @include config-type-subheading;
            padding: 8*$pxToRem 16*$pxToRem;
            display: flex;
            align-items: center;
            line-height: 1.25;

            svg {
              display: block;
              width: 42*$pxToRem;
              height: 29*$pxToRem;
              fill: $color-text-inverted;
              opacity: 0;
              transform: translateX(5*$pxToRem);
              transition: transform $transition-default,
                          opacity $transition-default;
            }

            &:hover,
            &:focus {
              svg {
                transform: translateX(20*$pxToRem);
                opacity: 1;
              }
            }
          }
        }

        // First-level <li>
        & > li {
          padding-right: 30*$pxToRem;

          &:hover,
          &:focus-within {
            // First-level <a>
            & > a {
              color: $color-white;
            }

            &::before {
              @include util-pseudo-layer;
              left: 100%;
            }

            // Nested <ul>
            ul {
              opacity: 1;
              z-index: 9;
            }
          }
        }
      }
    }

    // Laptop size
    @include media-below-lg-monitor {
      & > ul > ul {
        padding-left: 1vw;

        a {
          line-height: 1.25;
        }
      }
    }
  }

  // Mobile
  @include media-below-desktop {
    & > nav:first-child {
      transition: none;
      align-items: flex-start;
      padding-top: 30px;
      padding-bottom: 50px;
      background-size: auto;
      background-position: 0 0;
      background-repeat: repeat-y;

      a {
        color: $color-gray-7;
      }

      // First-level <ul>
      & > ul {
        width: 100%;
        box-sizing: border-box;
        padding: $padding-mobile;

        // First-level <li>
        & > li {
          position: relative;

          a {
            @include config-type-subheading;
            width: calc(100% - 50px);
          }

          .menu__sub-menu-wrapper {
            overflow: hidden;
            height: 0;
            transition: height $transition-default;
          }

          // Nested <ul>
          ul {
            margin: 0;
            padding: 0 0 $padding-mobile $padding-mobile;
            opacity: 0;
            transition: opacity $transition-default;

            // Second-level <a>
            a {
              text-transform: none;

              svg {
                display: none;
              }
            }
          }

          // mobile expand/collapse sub-nav trigger
          button {
            background: transparent;
            width: 40px;
            height: 40px;
            position: absolute;
            right: -8px;
            top: 3px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before,
            &:after {
              display: block;
              content: '';
              width: 14px;
              height: 2px;
              background: $color-white;
              position: absolute;
              opacity: 0.5;
              transition: transform $transition-default,
                          opacity $transition-default;
            }

            &:after {
              transform: rotate(90deg);
            }
          }

          &.menu__sub-menu-expanded {
            & > a {
              color: $color-white;
            }

            ul {
              opacity: 1;
            }

            button {
              &:before {
                opacity: 1;
              }

              &:after {
                transform: rotate(0deg);
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  aside {
    @include util-align-children-center;
    background: $color-gray-10;
    opacity: 0;
    transition: opacity 0.8s ease 0.1s;
    position: relative;

    .company-info {
      transition: opacity 0.8s ease 0.4s;
      opacity: 0;

      .logo {
        margin-bottom: 50*$pxToRem;

        svg {
          width: 261*$pxToRem;
          height: 142*$pxToRem;
        }
      }
    }

    .social {
      margin-top: 50*$pxToRem;
      transition: opacity 0.8s ease 0.6s;
      opacity: 0;
    }

    @include media-below(1200) {
      padding: 100*$pxToRem 0;

      .social {
        position: static;
        margin-top: 30*$pxToRem;
      }
    }

    @include media-below-desktop {
      transition: none;
      padding: 50*$pxToRem 0;

      .company-info {
        transition: none;
      }

    }
  }
}

// Hamburger / Close button
#menu-trigger {
  @include util-align-children-center;
  padding: 6*$pxToRem 20*$pxToRem 6*$pxToRem 0;
  position: fixed;
  top: 26*$pxToRem;
  right: 3.1vw;
  background: transparent;
  z-index: 9;
  transition: background-color 0.2s ease;
  outline-color: $color-text-inverted !important;
  width: 15.625rem;
  align-items: flex-end;

  svg {
    width: 60*$pxToRem;
    height: 48*$pxToRem;
    transition: fill 0.2s ease;
    fill: $color-text-inverted;
    transform: scaleX(-1);

    rect {
      transition: all 0.3s ease;
      transform-origin: center;
    }
  }

  span {
    color: $color-white;
    font-size: 12*$pxToRem;
    font-weight: 400;
    margin-left: 27*$pxToRem;
    margin-top: -10*$pxToRem;
    @include media-mobile-only {
      color: $color-black;
      margin-left: 25*$pxToRem;
    }
  }

  .phone-cta {
    position: absolute;
    right: 6rem;
    a {
      font-weight: bold;
      color: #fff;
    }
  }

  @include media-mobile-only {
    right: 0;
    top: 0;
    height: 60px;

    svg {
      // width: 40*$pxToRem;
      // height: 32*$pxToRem;
      fill: $color-text;
    }
  }

  @media (hover: hover) {
    &:hover {
      svg {
        rect {
          &:first-child {
            width: 20*$pxToRem;
          }
          &:nth-child(2) {
            width: 27*$pxToRem;
          }
          &:last-child {
            width: 34*$pxToRem;
          }
        }
      }
    }

    &:active {
      svg {
        fill: $color-text-inverted;
        transition-duration: 0s;
      }
    }
  }
}

// Inverted Menu Trigger State (when not over banner)

@include media-above-mobile {
  .js-menu-trigger-is-inverted {
    #menu-trigger {
      background: $color-white;

      .phone-cta {
        a {
          color: $color-black;
        }
      }

    }

  }
    
}

.js-menu-trigger-is-inverted,
.js-menu-is-active {
  #menu-trigger {
    outline-color: $color-text !important;

    span {
      color: $color-black;
      @include media-below(1200) {
        color: $color-white;
      }
    }

    svg {
      fill: $color-text;
    }

    @media (hover: hover) {
      &:hover {
        svg {
          fill: $color-accent;
        }
      }

      &:active {
        svg {
          fill: $color-text;
        }
      }
    }
  }
}

// Active Menu State
// menu will slide into view when document root has active class
// TODO: reconsider js prefix (in keeping with goal to always have parity between class/id prefix and css module name
.js-menu-is-active {
  #menu {
    transform: translateX(0);

    & > nav:first-child {
      opacity: 1;
      transition-duration: 1s;
    }

    aside {
      opacity: 1;

      .company-info,
      .social {
        opacity: 1;
      }
    }
  }

  #menu-trigger {
    background: transparent;

    .phone-cta {
      a {
        display: none;
      }
    }

    svg {
      rect {
        &:first-child {
          transform: rotate(45deg) translateY(1rem) translateX(0);
          // transform: rotate(45deg) translateY(4.5*$pxToRem);
        }

        &:nth-child(2), &:last-child {
          transform: rotate(-45deg) translateY(-.75rem) translateX(5*$pxToRem);
          // transform: rotate(-45deg) translateY(-4.5*$pxToRem);
          width: 34px;
        }
        &:last-child {
          opacity: 0;
        }
      }
    }
    @media (hover: hover) {
      &:hover {
        svg {
          rect {
            &:first-child {
              width: 34*$pxToRem;
            }
            &:nth-child(2) {
              width: 34*$pxToRem;
            }
          }
        }
      }
    }
    @include media-below(1200) {
      background: #2f2f2f;

      svg {
        fill: $color-text-inverted;
      }
    }
  }
}

// Prevents scrolling rest of page while menu modal is active
.js-viewport-frozen {
  overflow: hidden;

  @include media-mobile-only {
    .header,
    main,
    .footer,
    .menu-mobile-cta {
      visibility: hidden;
    }
  }
}

.menu-mobile-cta {
  display: none;

  @include media-mobile-only {
    @include config-fixed-menu($color-accent);
    display: block;
    bottom: 0;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 0;

      li {
        flex: 1;

        &:not(:last-child) {
          border-right: 1*$pxToRem solid $color-white;
        }
      }

      a {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        // text-transform: uppercase;
        height: 30*$pxToRem;
        color: $color-white;

        &:hover {
          color: $color-text;
        }
      }
    }
  }
}

// #menu__sub-menu-5, #menu__sub-menu-6, #menu__sub-menu-7, #menu__sub-menu-8 {
//   position: relative;
// }


#menu {
  aside {
    position: sticky;
    top: 0;
  }
  .submenu-title a {
    cursor: default;
    color: #ddd !important;
    font-size: 20*$pxToRem !important;
    text-transform: uppercase !important;
    svg {
      display: none !important;
    }
  }
  .menu-item-object-procedure {
    padding-left: 1rem;
  }
  #menu__sub-menu-5 li:last-of-type {
    margin-bottom: 30*$pxToRem !important;
  }
}
