/* base module: theme */

p,
h4,
h5,
h6,
blockquote,
ul,
ol,
table {
  margin-top: $content-spacing*$pxToRem;
  margin-bottom: $content-spacing*$pxToRem;

  & + .button,
  & + .button-icon {
    margin-top: ($content-spacing * 1.33)*$pxToRem;

    @include media-mobile-only {
      margin-top: $content-spacing*$pxToRem;
    }
  }

  @include media-mobile-only {
    margin-top: $content-spacing-mobile*$pxToRem;
    margin-bottom: $content-spacing-mobile*$pxToRem;
  }
}

h2,
.typography-heading-container:not(:first-child) {
  margin-top: (($content-spacing * 2) - ($offset-heading / 2))*$pxToRem;
}

h2 {
  margin-bottom: ($offset-heading / 2)*$pxToRem;

  @include media-mobile-only {
    margin-top: ($content-spacing-mobile * 2)*$pxToRem;
    margin-bottom: -6*$pxToRem;
  }

  span {
    margin-bottom: $offset-subheading-micro*$pxToRem;
  }

  & + div {
    margin-top: $content-spacing*$pxToRem;
  }

  @include media-above-mobile {
    &:first-child {
      margin-top: #{$offset-heading*$pxToRem};

      span {
        margin-top: #{-$offset-subheading-micro*$pxToRem};
      }
    }

    &:last-child {
      margin-bottom: #{$offset-heading*$pxToRem};
    }
  }
}

h2,
h3,
.typography-heading-container {
  & + p.typography-heavy {
    padding-right: 13%;

    @include media-mobile-only {
      padding-right: 0;
    }
  }
}

blockquote:not(figure blockquote) {
  margin-left: 0;
  color: $color-gray-2;
  position: relative;

  &::before {
    display: block;
    width: $border-thickness*$pxToRem;
    height: 100%;
    position: absolute;
    background: $color-accent;
    content: '';
    transform: translateX(-1.7em);
  }
}

// ul:not(nav ul):not(.accordion),
// ol:not(nav ol):not(.accordion),
blockquote:not(figure blockquote) {
  padding-left: ($content-spacing + ($content-spacing / 2))*$pxToRem;
}

// // list item not inside of a nav or part of accordion structure
// ul:not(nav ul):not(.accordion),
// ol:not(nav ol):not(.accordion) {
//   list-style: none;

//   li {
//     & + li {
//       margin-top: #{0.25 * ($line-height-body / $font-size-body)}em;
//     }

//     &::before {
//       display: block;
//       position: absolute;
//     }
//   }
// }

// // list item not inside of a nav or part of accordion structure
// ul li:not(nav li):not(.accordion > li) {
//   &::before {
//     content: '';
//     width: 0.375em;
//     height: 0.375em;
//     border-radius: 100%;
//     background: $color-accent;
//     transform: translate(-1.75em, 0.65em);
//   }
// }

// ol:not(nav ol):not(.accordion) {
//   counter-reset: li;

//   li {
//     &::before {
//       counter-increment: li;
//       font-weight: 700;
//       content: '.' counter(li);
//       color: $color-accent;
//       text-align: right;
//       direction: rtl;
//       transform: translateX(-1.75em);
//     }
//   }
// }


// li::before {content: "•"; color: red;
//   display: inline-block; width: 1em;
//   margin-left: -1em}


// .example ol li::before {content: counter(li); color: red;
//   display: inline-block; width: 1em; margin-left: -1.5em;
//   margin-right: 0.5em; text-align: right; direction: rtl}



// TODO: maybe move this to animation file
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



// TODO: Dev - get rid of this
#guide {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;

  & > div {
    gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 0 calc(6.3vw + #{$gutter-size-16});
    box-sizing: border-box;
    max-width: 1920px;
    outline: 3px solid red;
  }

  & > div > div {
    height: 100%;
    background: rgba(0,50,255,0.3);
    outline: 1px solid rgba(0,0,255,0.3);
    outline-offset: -1px;
  }
}

@media screen and (min-width: 1920px) {
  #guide > div {
    max-width: 1920px;
    padding: 0 137*$pxToRem;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.hori-center {
  align-items: center;
}


.color-accent {
  color: $color-accent
}

.column-reverse {
  display: -ms-flex;
  display: flex;
  flex-direction: column-reverse;
}

@include media-mobile-only {
  .column-reverse-mobile {
    display: -ms-flex;
    display: flex;
    flex-direction: column-reverse;
  }
}


.location {
  position: relative;
  margin-bottom: 40*$pxToRem;
  address {
    background-color: $color-black;
    position: absolute;
    bottom: -30*$pxToRem;
    left: 0;
    right: 0;
    width: 60%;
    padding: 50*$pxToRem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    h3 {
      font-size: 22*$pxToRem;
      color: $color-accent;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: .08em;
    }
    h5 {
      font-size: 22*$pxToRem;
      color: $color-accent;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: .08em;
    }
    a {
      color: $color-white;
      font-weight: 300;
    }
  }
}
@include media-below-desktop {
  .location {
    margin: 160*$pxToRem 0;
    address {
      bottom: -120*$pxToRem;
    }
  }
}
@include media-mobile-only {
  .location {
    margin: 100*$pxToRem 0;
    address {
      bottom: -140*$pxToRem;
    }
  }
}

.carecredit-logo {
  height: 45*$pxToRem;
  a {
    display: block;
  }
  svg {
    height: 45*$pxToRem;
  }
}

@include media-mobile-only {
  body .uwy.userway_p5 .uai {
    bottom: 40px !important;
  }
}


.relative {
  position: relative;
}

.sticky {
  position: sticky;
  top: 0;
}

.gallery-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 30*$pxToRem 0;
}

.patient-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30*$pxToRem 0;
}

.patient-info {
  margin-top: 20*$pxToRem;
}

.wpcf7-form p:first-of-type {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  @include media-mobile-only {
    grid-template-columns: 1fr;
  }
}

// gallery-listing
.gallery-listing__wrapper {
  .case-box__images {
    &.double {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4px;

      @include media-mobile-only {
          padding: 0 30px;
      }

    }
  }

  .case-box__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 2rem;
      border: 1px solid #000;

      @include media-mobile-only {
        margin: 0 10px;
      }
  }

  .c-btn__wrapper {
    margin-bottom: 1.5rem;
  }
}

// gallery-single
.patient-listing__wrapper {

  .case-box__images {

    &.double {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    }
  }

}

.gallery-home {
  &> div {
    display: flex;
    flex-direction: column;
  }

  .bg-gray-3 {
    padding: unset;
    flex-grow: 1;
  }
}

.term-brachioplasty {

	.gallery-listing__wrapper {

		.case-box__images.double {
			grid-template-columns: 1fr;

      @include media-mobile-only {
        padding: 0;
    }

		&> div:first-child {
			margin-top: 2.25rem;
		  }

      &> div:last-child {
        margin-bottom: 1.875rem;
        }

      img {
        margin: 0;
      }
		}

	}
}

.meet-doctor {

    .grid-2-col {

      .google-rating {
        transform: scale(.5);
        position: absolute;
        right: 0;
        bottom: 0;

        @include media-above(1441) {
          right: 150px;
        }

        @include media-mobile-only {
          position: relative;
          transform-origin: 0;
        }
      }
    }
  
}
